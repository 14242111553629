import values from 'lodash/values';
import createConfigurableObject from '../../../utils/configurationObject';
import { firstAllowedLink, combinePermissions } from '../../../utils/helpers';
import settingsGlobalTabs from './settingsGlobalTabs';
import sideMenuItem from './sideMenu';
import routes from './routes';
import { SETTINGS_CODE } from './consts';

export default (app) => {
    app.settingsGlobalTabs = app.settingsGlobalTabs || createConfigurableObject();
    app.settingsGlobalTabs.addMany(settingsGlobalTabs(app));

    app.settingsTabs = app.settingsTabs || createConfigurableObject();
    app.settingsTabs.addItem('global', {
        label: 'Global',
        link: () => firstAllowedLink(values(app.settingsGlobalTabs.getAll())),
        permission: () => combinePermissions(values(app.settingsGlobalTabs.getAll())),
        order: 110,
        children: values(app.settingsGlobalTabs.getAll()),
    });

    app.sideMenuItems.addItem(SETTINGS_CODE, sideMenuItem(app));
    app.routes.addMany(routes(app));
};
