import { ORDERS_DECISIONS_TITLE, ORDERS_RULES_TITLE, ORDERS_DECISIONS_ROUTE, ORDERS_RULES_ROUTE } from './consts';

export default () => ({
    orderDecisionSettings: {
        id: 'orderDecisionSettings',
        label: ORDERS_DECISIONS_TITLE,
        title: ORDERS_DECISIONS_TITLE,
        link: ORDERS_DECISIONS_ROUTE,
        permission: [],
        order: 10,
    },
    orderDecisionRuleSettings: {
        id: 'orderDecisionRuleSettings',
        label: ORDERS_RULES_TITLE,
        title: ORDERS_RULES_TITLE,
        link: ORDERS_RULES_ROUTE,
        permission: [],
        order: 20,
    },
});
