import values from 'lodash/values';
import createConfigurableObject from '../../../utils/configurationObject';
import { firstAllowedLink, combinePermissions } from '../../../utils/helpers';

import routes from './routes';
import vendorRoutes from './vendorRoutes';
import sideMenuItem from './sideMenu';
import settingsTabs from './settingsTabs';
import { TITLE_PREFIX } from './consts';

export default (app) => {
    app.settingsPurchaseOrdersTabs = app.settingsPurchaseOrdersTabs || createConfigurableObject();
    app.settingsPurchaseOrdersTabs.addMany(settingsTabs(app));

    app.settingsTabs.addItem('purchaseOrders', {
        label: TITLE_PREFIX,
        link: () => firstAllowedLink(values(app.settingsPurchaseOrdersTabs.getAll())),
        permission: () => combinePermissions(values(app.settingsPurchaseOrdersTabs.getAll())),
        order: 11,
        children: values(app.settingsPurchaseOrdersTabs.getAll()),
    });

    app.routes.addMany(routes(app));

    vendorRoutes(app);

    app.sideMenuItems.addItem('purchaseOrders', sideMenuItem(app));
};
