import {
    TITLE_PREFIX,
    INTEGRATIONS_INDEX_ROUTE,
    INTEGRATIONS_DETAILS_PATH,
    INTEGRATIONS_DETAILS_ROUTE,
    INTEGRATIONS_FORM_PATH,
    INTEGRATIONS_CREATE_FORM_ROUTE,
    INTEGRATIONS_EDIT_FORM_ROUTE,
    INTEGRATIONS_AUTHORIZE_COMPLETED_ROUTE,
    WEBHOOK_EVENTS_INDEX_PATH,
    WEBHOOK_EVENTS_INDEX_ROUTE,
    WEBHOOK_EVENTS_DETAILS_PATH,
    WEBHOOK_EVENTS_DETAILS_ROUTE,
} from './consts';

import { EMPTY_TITLE_CRUMB } from '../constants';
import withSettingsTabsWrapper from '../settings/components/withSettingsTabsWrapper';

export default (app) => ({
    settingIntegrations: {
        path: app.settingsTabs.getItem('integrations').link,
        title: app.settingsTabs.getItem('integrations').title,
        permission: app.settingsTabs.getItem('integrations').permission,
        breadcrumbConfig: {
            name: `${app.settingsTabs.getItem('integrations').label}`,
            clearPath: true,
        },
        props: () => ({
            detailsPageRoute: INTEGRATIONS_DETAILS_ROUTE,
            addFormPageRoute: INTEGRATIONS_CREATE_FORM_ROUTE,
            updateFormPageRoute: INTEGRATIONS_EDIT_FORM_ROUTE,
            webHookEventsPageRoute: WEBHOOK_EVENTS_INDEX_ROUTE,
        }),
        loader: () => import('@skunexus/integrations'),
        render: withSettingsTabsWrapper(app),
    },
    settingIntegrationForm: {
        path: INTEGRATIONS_FORM_PATH,
        title: `${TITLE_PREFIX} Form`,
        permission: 'connectors.index',
        breadcrumbConfig: { title: 'Add Integration', isReplaceble: true },
        props: () => ({
            gridPageRoute: INTEGRATIONS_INDEX_ROUTE,
        }),
        loader: () => import('@skunexus/integration-form'),
    },
    settingIntegrationDetails: {
        path: INTEGRATIONS_DETAILS_PATH,
        title: `${TITLE_PREFIX} Details`,
        permission: 'connectors.index',
        breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
        props: () => ({
            gridPageRoute: INTEGRATIONS_INDEX_ROUTE,
            updateFormPageRoute: INTEGRATIONS_EDIT_FORM_ROUTE,
            webHookEventsPageRoute: WEBHOOK_EVENTS_INDEX_ROUTE,
        }),
        loader: () => import('@skunexus/integration-details'),
    },
    integrationAuthorizeCompleted: {
        path: INTEGRATIONS_AUTHORIZE_COMPLETED_ROUTE,
        title: TITLE_PREFIX,
        permission: 'connectors.index',
        props: () => ({}),
        loader: () => import('./components/IntegrationAuthorizeCompleted'),
    },
    webhookEvents: {
        path: WEBHOOK_EVENTS_INDEX_PATH,
        title: `${TITLE_PREFIX} Webhook Events`,
        breadcrumbConfig: { title: 'Webhook Events', isReplaceble: true },
        permission: 'connectors.index',
        props: () => ({
            detailsPageRoute: WEBHOOK_EVENTS_DETAILS_ROUTE,
        }),
        loader: () => import('@skunexus/webhook-events'),
    },
    webhookEventDetails: {
        path: WEBHOOK_EVENTS_DETAILS_PATH,
        title: `${TITLE_PREFIX} Webhook Event`,
        breadcrumbConfig: { title: 'Event', isReplaceble: true },
        permission: 'connectors.index',
        props: () => ({
            gridPageRoute: WEBHOOK_EVENTS_INDEX_ROUTE,
        }),
        loader: () => import('@skunexus/webhook-event-details'),
    },
});
