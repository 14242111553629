import routes from './routes';
import { TITLE_PREFIX, INTEGRATIONS_INDEX_ROUTE } from './consts';

export default (app) => {
    app.settingsTabs.addItem('integrations', {
        label: TITLE_PREFIX,
        title: TITLE_PREFIX,
        link: INTEGRATIONS_INDEX_ROUTE,
        permission: 'connectors.index',
        order: 20,
    });

    app.routes.addMany(routes(app));
};
