import createConfigurableObject from '../../../utils/configurationObject';

import routes from './routes';
import vendorRoutes from './vendorRoutes';
import tabs from './tabs';
import sideMenuItem from './sideMenu';
import vendorSideMenuItems from './vendorSideMenuItems';
import vendorHeaderMenuItems from './vendorHeaderMenuItems';

export default (app) => {
    app.vendorTabs = app.vendorTabs || createConfigurableObject();
    app.vendorTabs.addMany(tabs(app));

    app.routes.addMany(routes(app));
    vendorRoutes(app);

    app.sideMenuItems.addItem('vendors', sideMenuItem(app));
    app.vendorSideMenuItems.addMany(vendorSideMenuItems(app));
    app.vendorHeaderMenuItems.addMany(vendorHeaderMenuItems(app));
};
