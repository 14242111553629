import values from 'lodash/values';
import { createMainLayout } from '../utils/helpers';
import app from '../config';

const getPreparedItems = (items) =>
    values(items).sort(({ order: orderA = 0 }, { order: orderB = 0 }) => orderA - orderB);

const mainLayoutProps = () => ({
    headerMenuItems: getPreparedItems(app.headerMenuItems.getAll()),
    sideMenuItems: getPreparedItems(app.sideMenuItems.getAll()),
});

export default createMainLayout(mainLayoutProps);
