import createConfigurableObject from '../../../utils/configurationObject';
import routes from './routes';
import sideMenuItem from './sideMenu';
import tabs from './tabs';

export default (app) => {
    app.receiveInventoryTabs = app.receiveInventoryTabs || createConfigurableObject();
    app.receiveInventoryTabs.addMany(tabs(app));
    app.routes.addMany(routes(app));
    app.sideMenuItems.addItem('receiveInventory', sideMenuItem(app));
};
