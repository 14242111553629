import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import createConfigurableObject from '../../../utils/configurationObject';

const routesToCopy = ['userProfile'];

export default (app) => {
    app.vendorRoutes = app.vendorRoutes || createConfigurableObject();

    forEach(routesToCopy, (routeName) => {
        const route = { ...app.routes.getItem(routeName) };
        if (!isEmpty(route)) {
            app.vendorRoutes.addItem(routeName, route);
        }
    });
};
