import values from 'lodash/values';
import { firstAllowedLink, combinePermissions } from '../../../utils/helpers';
import { SETTINGS_LABEL, SETTINGS_ICON } from './consts';

export default (app) => ({
    label: SETTINGS_LABEL,
    link: () =>
        firstAllowedLink(
            values(app.settingsTabs.getAll()).sort(({ order: orderA = 0 }, { order: orderB = 0 }) => orderA - orderB),
        ),
    icon: SETTINGS_ICON,
    permission: () => combinePermissions(values(app.settingsTabs.getAll())),
    order: 90,
});
