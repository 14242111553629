import { SHIP_DASHBOARD_ROUTE, PICKUP_DASHBOARD_ROUTE } from './consts';

export default () => ({
    label: 'Fulfillments',
    icon: 'orderStatus',
    permission: 'fulfillments.index',
    order: 60,
    items: [
        {
            label: 'Ship',
            link: SHIP_DASHBOARD_ROUTE,
            icon: 'shipment',
        },
        {
            label: 'Pickup',
            link: PICKUP_DASHBOARD_ROUTE,
            icon: 'store',
        },
    ],
});
