import values from 'lodash/values';
import createConfigurableObject from '../../../utils/configurationObject';
import { firstAllowedLink, combinePermissions } from '../../../utils/helpers';

import settingsTabs from './settingsTabs';
import routes from './routes';
import { WAREHOUSES_TITLE_PREFIX } from './consts';

export default (app) => {
    app.settingsWarehousesTabs = app.settingsWarehousesTabs || createConfigurableObject();
    app.settingsWarehousesTabs.addMany(settingsTabs(app));

    app.settingsTabs.addItem('warehouses', {
        label: WAREHOUSES_TITLE_PREFIX,
        link: () => firstAllowedLink(values(app.settingsWarehousesTabs.getAll())),
        permission: () => combinePermissions(values(app.settingsWarehousesTabs.getAll())),
        order: 3,
        children: values(app.settingsWarehousesTabs.getAll()),
    });

    app.routes.addMany(routes(app));
};
