import {
    WAREHOUSES_TITLE_PREFIX,
    WAREHOUSES_GRID_ROUTE,
    DESCRIPTORS_TITLE_PREFIX,
    DESCRIPTORS_GRID_ROUTE,
    DESCRIPTION_TEMPLATES_TITLE_PREFIX,
    DESCRIPTION_TEMPLATES_GRID_ROUTE,
    CARTS_TITLE_PREFIX,
    CARTS_GRID_ROUTE,
    TOTES_TITLE_PREFIX,
    TOTES_GRID_ROUTE,
    WAREHOUSE_CONFIGURATION_TITLE_PREFIX,
    WAREHOUSE_CONFIGURATION_GRID_ROUTE,
} from './consts';

export default () => ({
    warehouses: {
        id: 'Warehouses',
        label: WAREHOUSES_TITLE_PREFIX,
        title: WAREHOUSES_TITLE_PREFIX,
        link: WAREHOUSES_GRID_ROUTE,
        permission: 'warehouses.index',
        order: 10,
    },
    descriptors: {
        id: 'Descriptors',
        label: DESCRIPTORS_TITLE_PREFIX,
        title: DESCRIPTORS_TITLE_PREFIX,
        link: DESCRIPTORS_GRID_ROUTE,
        permission: 'settings - descriptors.index',
        order: 20,
    },
    descriptorTemplates: {
        id: 'Descriptor Templates',
        label: DESCRIPTION_TEMPLATES_TITLE_PREFIX,
        title: DESCRIPTION_TEMPLATES_TITLE_PREFIX,
        link: DESCRIPTION_TEMPLATES_GRID_ROUTE,
        permission: 'settings - description templates.index',
        order: 30,
    },
    carts: {
        id: 'Carts',
        label: CARTS_TITLE_PREFIX,
        title: CARTS_TITLE_PREFIX,
        link: CARTS_GRID_ROUTE,
        permission: 'carts.index',
        order: 40,
    },
    totes: {
        id: 'Totes',
        label: TOTES_TITLE_PREFIX,
        title: TOTES_TITLE_PREFIX,
        link: TOTES_GRID_ROUTE,
        permission: 'totes.index',
        order: 41,
    },
    configuration: {
        id: 'Warehouse Configurations',
        label: WAREHOUSE_CONFIGURATION_TITLE_PREFIX,
        title: WAREHOUSE_CONFIGURATION_TITLE_PREFIX,
        link: WAREHOUSE_CONFIGURATION_GRID_ROUTE,
        permission: 'settings - warehouses.manage descriptor values order',
        order: 50,
    },
});
