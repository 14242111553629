import React from 'react';
import { LoginLayout } from '@skunexus/component-catalog';
import { useConfigContext } from './contexts/ConfigContext';
import UserModel from './UserModel';
import SkunexusPortal from './skunexusPortal';
import VendorPortal from './vendorPortal';

function PortalSwitch() {
    const [{ isLoading }] = useConfigContext();

    if (isLoading) {
        return <LoginLayout landingMode />;
    }

    if (UserModel.isVendor()) {
        return <VendorPortal />;
    }

    return <SkunexusPortal />;
}

export default PortalSwitch;
