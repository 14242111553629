import createConfigurableObject from '../../../utils/configurationObject';

export default (app) => {
    app.vendorHeaderMenuItems = app.vendorHeaderMenuItems || createConfigurableObject();
    app.vendorHeaderMenuItems.addMany({
        logout: {
            hasBorder: true,
            label: 'Logout',
            link: '/logout',
            order: 100,
        },
    });
};
