import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, head } from 'lodash';

import logo from '../../assets/phg-logo.png';
import DateTimeZoneStore from '@skunexus/datetime-timezone-module';
import DateTimeFormatStore from '@skunexus/datetime-format-module';
import { Barcode, DetailsCardAddress } from '@skunexus/component-catalog';

import styles from './styles.module.css';

function Header({ details, company, barcodeConfig, onBarcodeLoaded }) {
    const { label, barcodes = [], updated_at, destinationAddress, vendor, vendorAddress } = details;
    const barcodeValue = get(head(barcodes), 'barcodeValue');

    const [barcodeIsLoaded, setBarcodeIsLoaded] = useState(false);
    const [logoIsLoaded, setLogoIsLoaded] = useState(false);

    useEffect(() => {
        if (barcodeIsLoaded && logoIsLoaded) {
            onBarcodeLoaded();
        }
    }, [barcodeIsLoaded, logoIsLoaded]);

    const handleLogoLoaded = () => {
        setLogoIsLoaded(true);
    };

    const loadLogo = (input) => {
        if (!input) return;
        const img = input;

        img.onload = handleLogoLoaded;
        img.onerror = handleLogoLoaded;
        if (img.complete) {
            handleLogoLoaded();
        }
    };

    const handleBarcodeLoaded = () => {
        setBarcodeIsLoaded(true);
    };

    return (
        <thead>
            <tr>
                <td colSpan={2}>
                    <table className={styles.header}>
                        <tbody>
                            <tr>
                                <td>
                                    <div className={styles.logoWrapper}>
                                        <img src={logo} ref={loadLogo} alt="PHG" width="160" />
                                    </div>
                                </td>
                                <td className={styles.companyWrapper}>
                                    <DetailsCardAddress data={company} />
                                </td>
                                <td />
                            </tr>
                            <tr>
                                <td colspan="2" className={styles.barcodeWrapper}>
                                    <Barcode
                                        type={get(head(barcodes), 'barcodeType')}
                                        containerStyle={{
                                            textAlign: 'left',
                                            width: '350px',
                                        }}
                                        onLoaded={handleBarcodeLoaded}
                                        {...barcodeConfig}
                                    >
                                        {barcodeValue}
                                    </Barcode>
                                </td>
                                <td className={styles.poInfo}>
                                    <h2>PURCHASE ORDER</h2>
                                    <p>
                                        DATE:
                                        <span>
                                            {DateTimeZoneStore.getDateTimeinFeTimeZone({
                                                date: updated_at,
                                                convertToDateFormat: DateTimeFormatStore.getFeDateFormat(),
                                            })}
                                        </span>
                                    </p>
                                    <p>
                                        PO: <span>{label}</span>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.addressWrapper}>
                                    <DetailsCardAddress
                                        title="Vendor"
                                        data={{
                                            ...vendorAddress,
                                            company: get(vendor, 'name', ''),
                                        }}
                                    />
                                </td>
                                <td />
                                <td className={styles.addressWrapper}>
                                    <DetailsCardAddress title="Ship to" data={destinationAddress} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </thead>
    );
}

Header.propTypes = {
    details: PropTypes.object,
    company: PropTypes.object,
    barcodeConfig: PropTypes.object,
    onBarcodeLoaded: PropTypes.func,
};

Header.defaultProps = {
    details: {},
    company: {},
    barcodeConfig: {},
    onBarcodeLoaded: () => {},
};

export default Header;
