import forEach from 'lodash/forEach';
import values from 'lodash/values';
import setupModules from '../setup';

const sortModules = (modules) => {
    const dependencies = {};
    values(modules).forEach(({ name, before, after }) => {
        if (!Object.prototype.hasOwnProperty.call(dependencies, name)) {
            dependencies[name] = [];
        }
        if (before) {
            before.forEach((dep) => {
                if (!Object.prototype.hasOwnProperty.call(dependencies, dep)) {
                    dependencies[dep] = [];
                }
                dependencies[dep].push(name);
            });
        }
        if (after) {
            after.forEach((dep) => {
                dependencies[name].push(dep);
            });
        }
    });

    const keys = Object.keys(dependencies);
    const used = new Set();
    const result = [];
    let i;
    let item;
    let length;

    /* eslint-disable prefer-destructuring, no-continue */
    do {
        length = keys.length;
        i = 0;
        while (i < keys.length) {
            if (dependencies[keys[i]].every(Set.prototype.has, used)) {
                item = keys.splice(i, 1)[0];
                result.push(item);
                used.add(item);
                continue;
            }
            i += 1;
        }
    } while (keys.length && keys.length !== length);
    /* eslint-enable prefer-destructuring, no-continue */

    result.push(...keys);

    return result.map((moduleName) => setupModules[moduleName]);
};

export default (app) => {
    forEach(sortModules(setupModules), (module) => {
        module.setup(app);
    });
};
