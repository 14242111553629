import values from 'lodash/values';
import createConfigurableObject from '../../../utils/configurationObject';
import { firstAllowedLink, combinePermissions } from '../../../utils/helpers';

import settingsTabs from './settingsTabs';
import routes from './routes';

export default (app) => {
    app.settingsAttributesTabs = app.settingsAttributesTabs || createConfigurableObject();
    app.settingsAttributesTabs.addMany(settingsTabs(app));

    app.settingsTabs.addItem('customAttributes', {
        label: 'Custom Attributes',
        link: () => firstAllowedLink(values(app.settingsAttributesTabs.getAll())),
        permission: () => combinePermissions(values(app.settingsAttributesTabs.getAll())),
        order: 1,
        children: values(app.settingsAttributesTabs.getAll()),
    });

    app.routes.addMany(routes(app));
};
