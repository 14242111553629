import axios from 'axios';
import { constants } from '@skunexus/utils';

const { API_PATH } = constants.CONFIG;

export function fetchConfig() {
    return axios.post(`${API_PATH}/query`, {
        query: `query V1Queries { 
            setting {
                fulfillment {
                    batchingEnabled
                }
                kitting {
                    is_enabled
                    force_entire_kits
                }
                system {
                    version
                }
            }
        }`,
    });
}
