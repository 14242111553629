import { CANCELLATION_TITLE_PREFIX, CANCELLATION_DOMAIN_NAME, CANCELLATION_DETAILS_ROUTE } from './consts';

import { ORDERS_DETAILS_ROUTE } from '../orders/consts';
import { PRODUCTS_DETAILS_ROUTE } from '../products/consts';
import UserModel from '../../../UserModel';

export default () => {
    const isPrintDetailsAvailable = () => false;
    const isConfirmPickupAvailable = () => false;

    const routers = {
        cancellationDetails: {
            path: `${CANCELLATION_DETAILS_ROUTE}/:id`,
            title: `${CANCELLATION_TITLE_PREFIX} > Details`,
            permission: ['fulfillments.show'],
            props: () => ({
                domain: CANCELLATION_DOMAIN_NAME,
                detailsPageRoute: CANCELLATION_DETAILS_ROUTE,
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                currentUserId: UserModel.getUserId(),
                infoProps: {
                    showBarcode: false,
                    showCancelButton: false,
                },
                isPrintDetailsAvailable,
                isConfirmPickupAvailable,
            }),
            loader: () => import('@skunexus/fulfillment-cancellation-details'),
        },
    };

    return routers;
};
