import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut, Pie } from 'react-chartjs-2';
import { TITLE_PREFIX, DASHBOARD_CODE, DASHBOARD_PATH, DASHBOARD_ROUTE } from './consts';

import { ORDERS_INDEX_ROUTE } from '../orders/consts';
import { SHIP_DASHBOARD_ROUTE, SHIP_BATCHING_ROUTE, SHIP_PICK_ROUTE, SHIP_PACK_ROUTE, SHIP_DISPATCHED_ROUTE, SHIP_PRINTED_ROUTE } from '../fulfillment/consts';

export default (app) => {
    const chartJSLib = { Chart, ArcElement, Tooltip, Legend };
    const reactCharts = { Doughnut, Pie };

    app.routes.addItem(DASHBOARD_CODE, {
        exact: true,
        path: DASHBOARD_PATH,
        title: TITLE_PREFIX,
        breadcrumbConfig: { title: 'Dashboard', clearPath: true },
        props: () => ({
            chartJSLib,
            reactCharts,
            tilesConfig: {
                'New Orders': {
                    icon: 'MenuUp',
                    color: 'primary',
                    redirect: ORDERS_INDEX_ROUTE,
                },
                'In Fulfillment': {
                    icon: 'Boxes',
                    color: 'warning',
                    redirect: SHIP_DASHBOARD_ROUTE,
                },
                'In Picking': {
                    icon: 'ShopWindow',
                    color: 'secondary',
                    redirect: SHIP_PICK_ROUTE,
                },
                'In Packing': {
                    icon: 'BoxSeam',
                    color: 'success',
                    redirect: SHIP_PACK_ROUTE,
                },
                'In Batching': {
                    icon: 'ShopWindow',
                    color: 'secondary',
                    redirect: SHIP_BATCHING_ROUTE,
                },
                'Ready to Ship': {
                    icon: 'Box2',
                    color: 'success',
                    redirect: SHIP_PRINTED_ROUTE
                },
                'Shipped Today': {
                    icon: 'Truck',
                    color: 'danger',
                    redirect: SHIP_DISPATCHED_ROUTE,
                },
            },
        }),
        loader: () => import('@skunexus/dashboard'),
    });

    app.sideMenuItems.addItem(DASHBOARD_CODE, {
        label: TITLE_PREFIX,
        link: DASHBOARD_ROUTE,
        icon: 'overview',
        order: 10,
        breadcrumbConfig: { title: 'Dashboard', clearPath: true },
    });

    app.headerMenuItems.addItem(DASHBOARD_CODE, {
        label: TITLE_PREFIX,
        link: DASHBOARD_ROUTE,
        icon: 'Grid',
        order: 10,
    });

    app.vendorRoutes.addItem(DASHBOARD_CODE, {
        exact: true,
        path: DASHBOARD_PATH,
        breadcrumbConfig: { title: 'Dashboard', clearPath: true },
        title: TITLE_PREFIX,
        props: () => ({
            chartJSLib,
            reactCharts,
        }),
        loader: () => import('@skunexus/dashboard'),
    });
};
