import React from 'react';

import noop from 'lodash/noop';
import reduce from 'lodash/reduce';

import { dynamicModule } from '@skunexus/redux';
import { provideRouteFactory } from '@skunexus/utils';

import app from './config';

const config = app.general.getAll();

export function createRoute({ render, ...props }) {
    const { name, mainLayout, path, title, props: routeProps } = props;

    const storeSelector = (state) => state[name] || {};
    const Route = provideRouteFactory(routeProps);
    const Component = dynamicModule(props);

    return (
        <Route
            exact
            key={name}
            layout={mainLayout}
            path={path}
            title={title}
            component={render ? render(Component) : Component}
            storeSelector={storeSelector}
            {...props}
        />
    );
}

export function createRoutes(routes, mainLayout) {
    return reduce(
        routes,
        (result, value) => {
            const { props = noop } = value;
            const createProps = (state, routeProps) => ({
                ...props(state, routeProps),
                ...config.modules[value.name],
            });
            result.push(
                createRoute({
                    mainLayout,
                    ...value,
                    props: createProps,
                }),
            );

            return result;
        },
        [],
    );
}
