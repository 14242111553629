import {
    VENDOR_DETAILS_ROUTE,
    VENDOR_PRODUCTS_GRID_ROUTE,
    VENDOR_CONTACTS_GRID_ROUTE,
    VENDOR_PURCHASE_ORDERS_GRID,
    VENDOR_OVERVIEW_TITLE,
} from './consts';
import { DASHBOARD_ROUTE } from '../dashboard/consts';

export default () => ({
    dashboard: {
        label: 'Dashboard',
        link: DASHBOARD_ROUTE,
        icon: 'overview',
    },
    overview: (vendorId) => ({
        label: VENDOR_OVERVIEW_TITLE,
        link: `${VENDOR_DETAILS_ROUTE}/${vendorId}`,
        icon: 'vendors',
    }),
    addresses: (vendorId) => ({
        label: 'Addresses',
        link: `/vendors/addresses/${vendorId}`,
        icon: 'CardList',
        permission: 'vendors - addresses.index',
    }),
    products: (vendorId) => ({
        label: 'Products',
        link: `${VENDOR_PRODUCTS_GRID_ROUTE}/${vendorId}`,
        icon: 'stock',
        permission: 'vendors - products.index',
    }),
    contacts: (vendorId) => ({
        label: 'Contacts',
        link: `${VENDOR_CONTACTS_GRID_ROUTE}/${vendorId}`,
        icon: 'customers',
        permission: 'vendors - contacts.index',
    }),
    purchaseOrder: (vendorId) => ({
        label: 'Purchase Orders',
        link: `${VENDOR_PURCHASE_ORDERS_GRID}/${vendorId}`,
        icon: 'purchase',
        permission: 'purchase orders.index',
    }),
    // DISABLED FOR NOW AS WE DONT USE IT
    // reports: (vendorId) => ({
    //     label: 'Reports & Payouts',
    //     link: `${VENDOR_REPORTS_ROUTE}/${vendorId}`,
    //     icon: 'resInventory',
    // }),
});
