import { TITLE_PREFIX, CYCLE_ACTIVE_ROUTE, CYCLE_HISTORICAL_ROUTE } from './consts';

export default () => ({
    cycleCountActiveGrid: {
        id: 'cycleCountActiveGrid',
        label: 'Active Cycles',
        title: `${TITLE_PREFIX} > Active Cycles`,
        link: CYCLE_ACTIVE_ROUTE,
        permission: ['cycle-counts.index'],
        order: 10,
    },
    cycleCountHistoricalGrid: {
        id: 'cycleCountHistoricalGrid',
        label: 'Historical Cycles',
        title: `${TITLE_PREFIX} > Historical Cycles`,
        link: CYCLE_HISTORICAL_ROUTE,
        permission: ['cycle-counts.index'],
        order: 20,
    },
});
