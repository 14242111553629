import { addNotesToAttributesListInDetails } from '../fulfillmentPickupPackingPage/packages/CustomFulfillmentPickupPacking/utils';

export default {
    name: 'fulfillmentPickupDetailsPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'pickupDetails';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {
            const routeProps = pageRoute.props;
            pageRoute.props = () => ({
                ...routeProps(),
                infoProps: {
                    customAttributeList: addNotesToAttributesListInDetails,
                },
                packingSlipPrintPageRoute: '/fulfillment-pickup/packing-slip',
            });

            app.routes.changeItem(pageName, pageRoute);
        }
    },
};
