import React from 'react';
import omit from 'lodash/omit';
import get from 'lodash/get';
import has from 'lodash/has';
import findIndex from 'lodash/findIndex';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { PrintLayout } from '@skunexus/component-catalog';
import { PrintLayout as BatchingPrintLayout } from '@skunexus/fulfillment-batching-print';

import {
    SHIP_TITLE_PREFIX,
    SHIP_DOMAIN_NAME,
    SHIP_WORKFLOW_NAME,
    SHIP_BATCHING_ROUTE,
    SHIP_BATCH_PRINT_ROUTE,
    SHIP_WAVES_ROUTE,
    SHIP_GROUP_PICK_ROUTE,
    SHIP_DASHBOARD_ROUTE,
    SHIP_CANCEL_ROUTE,
    SHIP_PACK_ROUTE,
    SHIP_PICK_ROUTE,
    SHIP_PRINT_LOGS_ROUTE,
    SHIP_PRINTED_ROUTE,
    SHIP_DETAILS_ROUTE,
    SHIP_GROUP_PICK_DETAILS_ROUTE,
    SHIP_PICKING_ROUTE,
    SHIP_GROUP_PICKING_ROUTE,
    SHIP_PACKING_ROUTE,
} from './consts';

import { ORDERS_DETAILS_ROUTE } from '../orders/consts';
import { PRODUCTS_DETAILS_ROUTE } from '../products/consts';
import { USERS_DETAILS_ROUTE } from '../users/consts';
import { SHIPMENT_DETAILS_ROUTE } from '../shipment/consts';
import { EMPTY_TITLE_CRUMB } from '../constants';

import { useConfigContext } from '../../../contexts/ConfigContext';
import UserModel from '../../../UserModel';
import MainTabs from './components/FulfillmentMainTabsLayout';
import withSettingsTabsWrapper from '../settings/components/withSettingsTabsWrapper';

export default (app) => {
    const customWaveGridConfig = (data) => {
        if (!has(data.config.filtering, 'state')) {
            set(data, 'config.filtering.state', { operator: 'in', value: ['not_picked', 'partial_picked'] });
        }
        return {
            ...data,
        };
    };

    const customWaveHistoryGridData = (data) => {
        const stateColIndex = findIndex(get(data, 'columns', []), ['field', 'state']);

        if (stateColIndex >= 0) {
            set(data, `columns[${stateColIndex}].isFilterable`, false);
            set(data, `columns[${stateColIndex}].defaultFilter`, ['picked']);
            set(data, `columns[${stateColIndex}].options`, [{ label: 'Picked', value: 'picked' }]);
        }

        return {
            ...data,
        };
    };

    const customWaveHistoryGridConfig = (data) => {
        set(data, 'config.filtering.state', { operator: 'in', value: ['picked'] });
        return {
            ...data,
        };
    };

    const routes = {
        fulfillmentShipMain: {
            path: app.fulfillmentShipTabs.getItem('main').link,
            title: app.fulfillmentShipTabs.getItem('main').title,
            permission: app.fulfillmentShipTabs.getItem('main').permission,
            breadcrumbConfig: { title: `${SHIP_TITLE_PREFIX}`, isTab: true, clearPath: true },
            props: () => ({
                detailsPageRoute: SHIP_DETAILS_ROUTE,
                pickPageRoute: SHIP_PICKING_ROUTE,
                packPageRoute: SHIP_PACKING_ROUTE,
                printLogsPageRoute: SHIP_PRINT_LOGS_ROUTE,
            }),
            loader: () => import('@skunexus/ship-main'),
            render: (Component) =>
                function (props) {
                    const [{ fulfillmentSetting: { isBatchingEnabled = false } = {} }] = useConfigContext();

                    const shipMainCustomGridSections = (config) => {
                        const newConfig = cloneDeep(config);
                        newConfig.sections = {
                            ...newConfig.sections,
                            batched: {
                                code: 'batched',
                                title: 'Batching',
                                path: SHIP_BATCHING_ROUTE,
                                status: ['batched', 'pick', 'pick_in_progress', 'printable'],
                                order: 10,
                            },
                            printed: {
                                code: 'printed',
                                title: 'Printed',
                                path: SHIP_PRINTED_ROUTE,
                                order: 20,
                            },
                        };
                        newConfig.sections = omit(newConfig.sections, ['pick', 'pack']);
                        return newConfig;
                    };

                    return (
                        <MainTabs
                            showCartFilter={false}
                            showAssignUserFilter={false}
                            {...props}
                            name="fulfillmentShipMain"
                        >
                            <Component
                                {...props}
                                customGridSections={isBatchingEnabled ? shipMainCustomGridSections : undefined}
                            />
                        </MainTabs>
                    );
                },
        },
        fulfillmentShipPrintLogs: {
            path: app.fulfillmentShipTabs.getItem('printLogs').link,
            title: app.fulfillmentShipTabs.getItem('printLogs').title,
            permission: app.fulfillmentShipTabs.getItem('printLogs').permission,
            breadcrumbConfig: {
                title: app.fulfillmentShipTabs.getItem('printLogs').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-print-logs'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs
                            showCartFilter={false}
                            showAssignUserFilter={false}
                            {...props}
                            name="fulfillmentShipPrintLogs"
                        >
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        },
        fulfillmentShipDispatched: {
            path: app.fulfillmentShipTabs.getItem('dispatched').link,
            title: app.fulfillmentShipTabs.getItem('dispatched').title,
            permission: app.fulfillmentShipTabs.getItem('dispatched').permission,
            breadcrumbConfig: {
                title: app.fulfillmentShipTabs.getItem('dispatched').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                packingPageRoute: SHIP_PACKING_ROUTE,
                printLogsPageRoute: SHIP_PRINT_LOGS_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-dispatched'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs showCartFilter={false} {...props} name="fulfillmentShipDispatched">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        },
        fulfillmentShipFulfilled: {
            path: app.fulfillmentShipTabs.getItem('finalized').link,
            title: app.fulfillmentShipTabs.getItem('finalized').title,
            permission: app.fulfillmentShipTabs.getItem('finalized').permission,
            breadcrumbConfig: {
                title: app.fulfillmentShipTabs.getItem('finalized').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-fulfilled'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs showCartFilter={false} {...props} name="fulfillmentShipFulfilled">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        },
        fulfillmentAll: {
            path: app.fulfillmentShipTabs.getItem('all').link,
            title: app.fulfillmentShipTabs.getItem('all').title,
            permission: app.fulfillmentShipTabs.getItem('all').permission,
            breadcrumbConfig: { title: app.fulfillmentShipTabs.getItem('all').label, isReplaceble: true, isTab: true },
            props: () => ({
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                orderDetailsRoute: ORDERS_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-all'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs showCartFilter={false} showAssignUserFilter={false} {...props} name="fulfillmentAll">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        },
        fulfillmentShipCancelled: {
            path: app.fulfillmentShipTabs.getItem('cancelled').link,
            title: app.fulfillmentShipTabs.getItem('cancelled').title,
            permission: app.fulfillmentShipTabs.getItem('cancelled').permission,
            breadcrumbConfig: {
                title: app.fulfillmentShipTabs.getItem('cancelled').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-cancelled'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="fulfillmentShipCancelled">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        },
        fulfillmentHospital: {
            path: app.fulfillmentShipTabs.getItem('hospital').link,
            title: app.fulfillmentShipTabs.getItem('hospital').title,
            permission: app.fulfillmentShipTabs.getItem('hospital').permission,
            breadcrumbConfig: {
                title: app.fulfillmentShipTabs.getItem('hospital').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
                cancelPageRoute: SHIP_CANCEL_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-hospital'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="fulfillmentHospital">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        },
        fulfillmentShipDetails: {
            path: `${SHIP_DETAILS_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Details`,
            breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                pickingPageRoute: SHIP_PICKING_ROUTE,
                packingPageRoute: SHIP_PACKING_ROUTE,
                packPageRoute: SHIP_PACK_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                currentUserId: UserModel.getUserId(),
            }),
            permission: ['fulfillments.picking', 'fulfillments.packing'],
            loader: () => import('@skunexus/fulfillment-details'),
            render: (Component) =>
                function (props) {
                    const [{ fulfillmentSetting: { isBatchingEnabled = false } = {} }] = useConfigContext();
                    return <Component {...props} batchEnabled={isBatchingEnabled} />;
                },
        },
        fulfillmentShipDetailsPrint: {
            path: `${SHIP_DETAILS_ROUTE}/print/:id`,
            title: `${SHIP_TITLE_PREFIX} > Details > Print Preview`,
            breadcrumbConfig: { title: 'Print Preview', isReplaceble: true },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                pickingPageRoute: SHIP_PICKING_ROUTE,
                packingPageRoute: SHIP_PACKING_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                currentUserId: UserModel.getUserId(),
            }),
            layout: PrintLayout,
            permission: ['fulfillments.show'],
            loader: () => import('@skunexus/fulfillment-details'),
        },
        fulfillmentShipPick: {
            path: app.fulfillmentShipTabs.getItem('pick').link,
            title: app.fulfillmentShipTabs.getItem('pick').title,
            permission: app.fulfillmentShipTabs.getItem('pick').permission,
            breadcrumbConfig: { title: app.fulfillmentShipTabs.getItem('pick').label, isReplaceble: true, isTab: true },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: SHIP_PICKING_ROUTE,
                groupPageRoute: SHIP_GROUP_PICKING_ROUTE,
                currentUserId: UserModel.getUserId(),
            }),
            loader: () => import('@skunexus/fulfillment-pick'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="fulfillmentShipPick">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
            batchingMode: false,
        },
        fulfillmentShipPicking: {
            path: `${SHIP_PICKING_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Picking`,
            permission: ['fulfillments.picking'],
            breadcrumbConfig: { title: 'Picking', isReplaceble: true },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                pickingPageRoute: SHIP_PICKING_ROUTE,
                packingPageRoute: SHIP_PACKING_ROUTE,
                pickPageRoute: SHIP_PICK_ROUTE,
                cancelPageRoute: SHIP_CANCEL_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                userDetailsPageRoute: USERS_DETAILS_ROUTE,
                currentUserId: UserModel.getUserId(),
            }),
            loader: () => import('@skunexus/fulfillment-picking'),
            batchingMode: false,
            render: (Component) =>
                function (props) {
                    const [{ setting }] = useConfigContext();
                    const { isKittingEnabled, isForceEntireKittingEnabled } = setting;

                    return <Component {...props} {...{ isKittingEnabled, isForceEntireKittingEnabled }} />;
                },
        },
        fulfillmentCancel: {
            path: `${SHIP_CANCEL_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Cancel`,
            permission: ['fulfillments.index'],
            breadcrumbConfig: { title: 'Cancel', isReplaceble: true },
            props: () => ({
                domain: SHIP_DOMAIN_NAME,
                packPageRoute: SHIP_PACK_ROUTE,
                packingPageRoute: SHIP_PACKING_ROUTE,
                pickPageRoute: SHIP_PICK_ROUTE,
                pickingPageRoute: SHIP_PICKING_ROUTE,
                dashboardPageRoute: SHIP_DASHBOARD_ROUTE,
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                showItemsImages: true,
            }),
            loader: () => import('@skunexus/fulfillment-cancel'),
            render: (Component) =>
                function (props) {
                    const [{ setting }] = useConfigContext();
                    const { isKittingEnabled, isForceEntireKittingEnabled } = setting;

                    return <Component {...props} {...{ isKittingEnabled, isForceEntireKittingEnabled }} />;
                },
        },
        fulfillmentShipGroupPick: {
            path: app.fulfillmentShipTabs.getItem('groupPick').link,
            title: app.fulfillmentShipTabs.getItem('groupPick').title,
            permission: app.fulfillmentShipTabs.getItem('groupPick').permission,
            breadcrumbConfig: {
                title: app.fulfillmentShipTabs.getItem('groupPick').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                detailsPageRoute: SHIP_GROUP_PICK_DETAILS_ROUTE,
                currentUserId: UserModel.getUserId(),
            }),
            loader: () => import('@skunexus/fulfillment-group-pick'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="fulfillmentShipGroupPick">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
            batchingMode: false,
        },
        fulfillmentShipGroupPicking: {
            path: `${SHIP_GROUP_PICKING_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Group Picking`,
            permission: ['fulfillments.picking'],
            breadcrumbConfig: { title: 'Group Picking', isReplaceble: true },
            props: () => ({
                detailsPageRoute: SHIP_GROUP_PICK_DETAILS_ROUTE,
                printPageRoute: `${SHIP_GROUP_PICK_DETAILS_ROUTE}/print`,
                packPageRoute: SHIP_PACK_ROUTE,
                groupPickPageRoute: SHIP_GROUP_PICK_ROUTE,
                productDetailsRoute: PRODUCTS_DETAILS_ROUTE,
                userDetailsPageRoute: USERS_DETAILS_ROUTE,
                scanQuantityConfirmation: true,
                showCounterButtons: false,
            }),
            loader: () => import('@skunexus/fulfillment-group-picking'),
            batchingMode: false,
        },
        fulfillmentShipGroupPickDetails: {
            path: `${SHIP_GROUP_PICK_DETAILS_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Group Pick Details`,
            permission: ['fulfillments.picking'],
            breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
            props: () => ({
                fulfillmentDetailsPageRoute: SHIP_DETAILS_ROUTE,
                wavePageRoute: SHIP_WAVES_ROUTE,
                groupPickPageRoute: SHIP_GROUP_PICK_ROUTE,
                pickingPageRoute: SHIP_GROUP_PICKING_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                currentUserId: UserModel.getUserId(),
            }),
            loader: () => import('@skunexus/fulfillment-group-pick-details'),
        },
        fulfillmentShipBatching: {
            path: app.fulfillmentShipTabs.getItem('batching').link,
            title: app.fulfillmentShipTabs.getItem('batching').title,
            permission: app.fulfillmentShipTabs.getItem('batching').permission,
            breadcrumbConfig: { title: app.fulfillmentShipTabs.getItem('batching').label, isReplaceble: true },
            props: () => ({
                printRoute: SHIP_BATCH_PRINT_ROUTE,
                fulfillmentDetailsRoute: SHIP_DETAILS_ROUTE,
                orderDetailsRoute: ORDERS_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-batching'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs
                            showCartFilter={false}
                            showAssignUserFilter={false}
                            {...props}
                            name="fulfillmentShipBatching"
                        >
                            <Component {...props} />
                        </MainTabs>
                    );
                },
            batchingMode: true,
        },
        fulfillmentShipBatchingPrint: {
            path: '/fulfillment-ship/batch-print',
            title: `${SHIP_TITLE_PREFIX} > Batching`,
            permission: app.fulfillmentShipTabs.getItem('batching').permission,
            breadcrumbConfig: { title: `Batching`, isReplaceble: true },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                fulfillmentDetailsPageRoute: SHIP_DETAILS_ROUTE,
                returnRoute: SHIP_BATCHING_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-batching-print'),
            batchingMode: true,
        },
        fulfillmentShipPrinted: {
            path: app.fulfillmentShipTabs.getItem('printed').link,
            title: app.fulfillmentShipTabs.getItem('printed').title,
            permission: app.fulfillmentShipTabs.getItem('printed').permission,
            breadcrumbConfig: { title: app.fulfillmentShipTabs.getItem('printed').label, isReplaceble: true },
            props: () => ({
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-printed'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs
                            showCartFilter={false}
                            showAssignUserFilter={false}
                            {...props}
                            name="fulfillmentShipPrinted"
                        >
                            <Component {...props} />
                        </MainTabs>
                    );
                },

            batchingMode: true,
        },
        fulfillmentShipPack: {
            path: app.fulfillmentShipTabs.getItem('pack').link,
            title: app.fulfillmentShipTabs.getItem('pack').title,
            permission: app.fulfillmentShipTabs.getItem('pack').permission,
            breadcrumbConfig: { title: app.fulfillmentShipTabs.getItem('pack').label, isReplaceble: true, isTab: true },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: SHIP_PACKING_ROUTE,
                packingPageRoute: SHIP_PACKING_ROUTE,
                printLogsPageRoute: SHIP_PRINT_LOGS_ROUTE,
                currentUserId: UserModel.getUserId(),
            }),
            loader: () => import('@skunexus/fulfillment-pack'),
            render: (Component) =>
                function (props) {
                    const { history } = props;
                    return (
                        <MainTabs {...props} name="fulfillmentShipPack">
                            <Component
                                {...props}
                                onFailedToPurchaseLabel={(fulfillmentId, barcode) =>
                                    history.push(`/fulfillment-ship/packing/${fulfillmentId}?barcode=${barcode}`)
                                }
                            />
                        </MainTabs>
                    );
                },
            batchingMode: false,
        },
        fulfillmentShipPacking: {
            path: `${SHIP_PACKING_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Packing`,
            permission: ['fulfillments.packing'],
            breadcrumbConfig: { title: 'Packing', isReplaceble: true },
            props: () => ({
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                pickingPageRoute: SHIP_PICKING_ROUTE,
                packingPageRoute: SHIP_PACKING_ROUTE,
                packPageRoute: SHIP_PACK_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                currentUserId: UserModel.getUserId(),
                showItemsImages: true,
            }),
            loader: () => import('@skunexus/fulfillment-packing'),
        },
        fulfillmentWaves: {
            path: app.fulfillmentWavesTabs.getItem('waves').link,
            title: app.fulfillmentWavesTabs.getItem('waves').title,
            permission: app.fulfillmentWavesTabs.getItem('waves').permission,
            breadcrumbConfig: {
                title: app.fulfillmentShipTabs.getItem('waves').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                detailsPageRoute: SHIP_GROUP_PICK_DETAILS_ROUTE,
                customGridConfig: customWaveGridConfig,
            }),
            loader: () => import('@skunexus/fulfillment-waves'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="fulfillmentWaves">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
            batchingMode: false,
        },
        fulfillmentWavesHistory: {
            path: app.fulfillmentWavesTabs.getItem('wavesHistory').link,
            title: app.fulfillmentWavesTabs.getItem('wavesHistory').title,
            permission: app.fulfillmentWavesTabs.getItem('wavesHistory').permission,
            breadcrumbConfig: {
                title: app.fulfillmentWavesTabs.getItem('wavesHistory').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                detailsPageRoute: SHIP_GROUP_PICK_DETAILS_ROUTE,
                gridConfig: {
                    id: 'fulfillment-wave-history-grid',
                    domain: SHIP_DOMAIN_NAME,
                    workflow: SHIP_WORKFLOW_NAME,
                },
                releaseWaveEnabled: false,
                customGridData: customWaveHistoryGridData,
                customGridConfig: customWaveHistoryGridConfig,
            }),
            loader: () => import('@skunexus/fulfillment-waves'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="fulfillmentWavesHistory">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
            batchingMode: false,
        },
        fulfillmentWavesSettings: {
            path: app.settingsFulfillmentTabs.getItem('waves').link,
            title: app.settingsFulfillmentTabs.getItem('waves').label,
            permission: app.settingsFulfillmentTabs.getItem('waves').permission,
            breadcrumbConfig: {
                title: `Fulfillment - ${app.settingsFulfillmentTabs.getItem('waves').label}`,
                clearPath: true,
            },
            props: () => ({}),
            loader: () => import('@skunexus/waves'),
            render: withSettingsTabsWrapper(app),
        },
    };

    return routes;
};
