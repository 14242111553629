import values from 'lodash/values';
import createConfigurableObject from '../../../utils/configurationObject';
import { firstAllowedLink, combinePermissions } from '../../../utils/helpers';

import queueTabs from './queueTabs';
import routes from './routes';
import { QUEUE_LABEL } from './consts';

export default (app) => {
    app.queueTabs = app.queueTabs || createConfigurableObject();
    app.queueTabs.addMany(queueTabs(app));

    app.settingsTabs.addItem('queue', {
        label: QUEUE_LABEL,
        link: () => firstAllowedLink(values(app.queueTabs.getAll())),
        permission: () => combinePermissions(values(app.queueTabs.getAll())),
        order: 40,
        children: values(app.queueTabs.getAll()),
    });

    app.routes.addMany(routes(app));
};
