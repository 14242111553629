import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { createRoutes } from '../createRoutes';
import app from '../config';
import logout from '../routes/logout';

import { useConfigContext } from '../contexts/ConfigContext';
import { filterAccessibleItems, keyedRoutes } from '../utils/helpers';
import MainLayout from './MainLayout';

export default function () {
    const [{ fulfillmentSetting: { isBatchingEnabled = false } = {} }] = useConfigContext();
    const accessibleRoutes = filterAccessibleItems(keyedRoutes(app.routes.getAll()));

    const accessibleRoutesFitleredByBatchingMode = accessibleRoutes.filter(
        ({ batchingMode = undefined }) => batchingMode === isBatchingEnabled || batchingMode === undefined,
    );

    return (
        <Switch>
            {createRoutes(accessibleRoutesFitleredByBatchingMode, MainLayout)}
            {logout()}
            <Redirect path="*" to="/dashboard" />
        </Switch>
    );
}
