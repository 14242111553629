// PAGE TITLE
export const TITLE_PREFIX = 'Purchase Order';

// ROUTES
export const PO_ROUTE_PREFIX = 'purchase-orders';
export const PO_INDEX_ROUTE = `/${PO_ROUTE_PREFIX}`;
export const PO_DETAILS_PATH = `/${PO_ROUTE_PREFIX}/:id`;
export const PO_DETAILS_ROUTE = `/${PO_ROUTE_PREFIX}`;
export const PO_PRINT_PATH = `/${PO_ROUTE_PREFIX}/print/:id`;
export const PO_PRINT_ROUTE = `/${PO_ROUTE_PREFIX}/print`;
export const PO_FORM_PATH = `/${PO_ROUTE_PREFIX}/:action(add|edit)/:id?`;
export const PO_CREATE_FORM_ROUTE = `/${PO_ROUTE_PREFIX}/add`;
export const PO_EDIT_FORM_ROUTE = `/${PO_ROUTE_PREFIX}/edit`;
export const PO_RESTOCK_PATH = `/${PO_ROUTE_PREFIX}/restock/:id?`;
export const PO_RESTOCK_ROUTE = `/${PO_ROUTE_PREFIX}/restock`;

// SETTINGS ROUTES
export const PO_SETTINGS_ROUTE_PREFIX = 'settings/purchase-orders';
export const PO_SETTINGS_STATUSES_ROUTE = `/${PO_SETTINGS_ROUTE_PREFIX}/statuses`;
export const PO_SETTINGS_NUMBER_ROUTE = `/${PO_SETTINGS_ROUTE_PREFIX}/po-number`;
