import { useEffect } from 'react';

import get from 'lodash/get';
import app from '../../config';

const localizejsEnabled = get(app.general.getItem('localizejs'), 'enabled', false);
const localizejsAppKey = get(app.general.getItem('localizejs'), 'appKey');
const localizejsConfig = get(app.general.getItem('localizejs'), 'config', {});

export default () => {
    useEffect(() => {
        if (localizejsEnabled && localizejsAppKey && window.Localize) {
            try {
                window.Localize.initialize({ key: localizejsAppKey, ...localizejsConfig });
            } catch (error) {
                /* eslint-disable-next-line no-console */
                console.error(error);
            }
        }
    }, []);
    return null;
};
