import { useEffect } from 'react';
import { get } from 'lodash';
import useConfigApi from './hooks/useConfigApi';

const useConfig = () => {
    const [{ data: { setting = {} } = {}, isLoading = false }, { fetchConfig }] = useConfigApi();

    useEffect(() => {
        fetchConfig();
    }, []);

    return [
        {
            version: get(setting, 'system.version'),
            setting: {
                isKittingEnabled: get(setting, 'kitting.is_enabled', false),
                isForceEntireKittingEnabled: get(setting, 'kitting.force_entire_kits', false),
            },
            fulfillmentSetting: {
                isBatchingEnabled: get(setting, 'fulfillment.batchingEnabled', false),
            },
            isLoading,
        },
        {},
    ];
};

export default useConfig;
