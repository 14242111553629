// LABELS
export const QUEUE_LABEL = 'Queue Jobs';
export const QUEUE_FAILED_JOBS_LABEL = `Failed Jobs`;
export const QUEUE_SPAWNED_JOBS_LABEL = `Spawned Jobs`;

// PAGE TITLE
export const TITLE_PREFIX = `Queue`;
export const QUEUE_FAILED_JOBS_TITLE = `${TITLE_PREFIX} > ${QUEUE_FAILED_JOBS_LABEL}`;
export const QUEUE_SPAWNED_JOBS_TITLE = `${TITLE_PREFIX} > ${QUEUE_SPAWNED_JOBS_LABEL}`;

// ROUTES
export const QUEUE_ROUTE_PREFIX = 'queue';
export const QUEUE_FAILED_JOBS_ROUTE = `/${QUEUE_ROUTE_PREFIX}/failed`;
export const QUEUE_SPAWNED_JOBS_ROUTE = `/${QUEUE_ROUTE_PREFIX}/spawned`;
