import settingsTabs from './settingsTabs';
import routes from './routes';
import vendorRoutes from './vendorRoutes';
import { PRODUCTIVITY_REPORT_TITLE_PREFIX, USERS_PRODUCTIVITY_REPORT_ROUTE } from './consts';

export default (app) => {
    const profleMenuItem = {
        label: 'My Profile',
        icon: 'PersonCircle',
        link: '/profile',
        order: 11,
    };
    app.headerMenuItems.addItem('profile', profleMenuItem);
    app.vendorHeaderMenuItems.addItem('profile', profleMenuItem);

    app.settingsTabs.addMany(settingsTabs(app));
    app.routes.addMany(routes(app));
    vendorRoutes(app);

    app.sideMenuItems.addMany({
        usersProductivityReport: {
            label: PRODUCTIVITY_REPORT_TITLE_PREFIX,
            link: USERS_PRODUCTIVITY_REPORT_ROUTE,
            icon: 'BarChartLine',
            permission: ['users - productivity reports.index'],
            order: 82,
        },
    });
};
