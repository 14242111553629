// CUSTOMER
export const CUSTOMER_ATTRIBUTES_CODE = 'customers';
export const CUSTOMER_ATTRIBUTES_GRID_TITLE = 'Customer Attributes';
export const CUSTOMER_ATTRIBUTES_FORM_TITLE = 'Customer Attribute Form';
export const CUSTOMER_ATTRIBUTES_GRID_ROUTE = `/settings/${CUSTOMER_ATTRIBUTES_CODE}/attributes`;
export const CUSTOMER_ATTRIBUTES_FORM_ROUTE = `/settings/${CUSTOMER_ATTRIBUTES_CODE}/attribute-form`;
export const CUSTOMER_ATTRIBUTES_FORM_PATH = `${CUSTOMER_ATTRIBUTES_FORM_ROUTE}/:id?`;

// PURCHASE ORDER
export const PO_ATTRIBUTES_CODE = 'purchase orders';
export const PO_ATTRIBUTES_GRID_TITLE = 'Purchase Order Attributes';
export const PO_ATTRIBUTES_FORM_TITLE = 'Purchase Order Attribute Form';
export const PO_ATTRIBUTES_GRID_ROUTE = `/settings/purchase-orders/attributes`;
export const PO_ATTRIBUTES_FORM_ROUTE = `/settings/purchase-orders/attribute-form`;
export const PO_ATTRIBUTES_FORM_PATH = `${PO_ATTRIBUTES_FORM_ROUTE}/:id?`;

// PRODUCT
export const PRODUCT_ATTRIBUTES_CODE = 'products';
export const PRODUCT_ATTRIBUTES_GRID_TITLE = 'Product Attributes';
export const PRODUCT_ATTRIBUTES_FORM_TITLE = 'Product Attribute Form';
export const PRODUCT_ATTRIBUTES_GRID_ROUTE = `/settings/${PRODUCT_ATTRIBUTES_CODE}/attributes`;
export const PRODUCT_ATTRIBUTES_FORM_ROUTE = `/settings/${PRODUCT_ATTRIBUTES_CODE}/attribute-form`;
export const PRODUCT_ATTRIBUTES_FORM_PATH = `${PRODUCT_ATTRIBUTES_FORM_ROUTE}/:id?`;

// ORDER
export const ORDER_ATTRIBUTES_CODE = 'orders';
export const ORDER_ATTRIBUTES_GRID_TITLE = 'Order Attributes';
export const ORDER_ATTRIBUTES_FORM_TITLE = 'Order Attribute Form';
export const ORDER_ATTRIBUTES_GRID_ROUTE = `/settings/${ORDER_ATTRIBUTES_CODE}/attributes`;
export const ORDER_ATTRIBUTES_FORM_ROUTE = `/settings/${ORDER_ATTRIBUTES_CODE}/attribute-form`;
export const ORDER_ATTRIBUTES_FORM_PATH = `${ORDER_ATTRIBUTES_FORM_ROUTE}/:id?`;

// USER
export const USER_ATTRIBUTES_CODE = 'users';
export const USER_ATTRIBUTES_GRID_TITLE = 'User Attributes';
export const USER_ATTRIBUTES_FORM_TITLE = 'User Attribute Form';
export const USER_ATTRIBUTES_GRID_ROUTE = `/settings/${USER_ATTRIBUTES_CODE}/attributes`;
export const USER_ATTRIBUTES_FORM_ROUTE = `/settings/${USER_ATTRIBUTES_CODE}/attribute-form`;
export const USER_ATTRIBUTES_FORM_PATH = `${USER_ATTRIBUTES_FORM_ROUTE}/:id?`;

// VENDOR
export const VENDOR_ATTRIBUTES_CODE = 'vendors';
export const VENDOR_ATTRIBUTES_GRID_TITLE = 'Vendor Attributes';
export const VENDOR_ATTRIBUTES_FORM_TITLE = 'Vendor Product Attribute Form';
export const VENDOR_ATTRIBUTES_GRID_ROUTE = `/settings/${VENDOR_ATTRIBUTES_CODE}/attributes`;
export const VENDOR_ATTRIBUTES_FORM_ROUTE = `/settings/${VENDOR_ATTRIBUTES_CODE}/attribute-form`;
export const VENDOR_ATTRIBUTES_FORM_PATH = `${VENDOR_ATTRIBUTES_FORM_ROUTE}/:id?`;

// VENDOR PRODUCT
export const VENDOR_PRODUCT_ATTRIBUTES_GRID_TITLE = 'Vendor Product Attributes';
export const VENDOR_PRODUCT_ATTRIBUTES_FORM_TITLE = 'Vendor Product Attribute Form';
export const VENDOR_PRODUCT_ATTRIBUTES_GRID_ROUTE = `/settings/vendor-products/attributes`;
export const VENDOR_PRODUCT_ATTRIBUTES_FORM_ROUTE = `/settings/vendor-products/attribute-form`;
export const VENDOR_PRODUCT_ATTRIBUTES_FORM_PATH = `${VENDOR_PRODUCT_ATTRIBUTES_FORM_ROUTE}/:id?`;

// WAREHOUSE
export const WAREHOUSE_ATTRIBUTES_CODE = 'warehouses';
export const WAREHOUSE_ATTRIBUTES_GRID_TITLE = 'Warehouse Attributes';
export const WAREHOUSE_ATTRIBUTES_FORM_TITLE = 'Warehouse Attribute Form';
export const WAREHOUSE_ATTRIBUTES_GRID_ROUTE = `/settings/${WAREHOUSE_ATTRIBUTES_CODE}/attributes`;
export const WAREHOUSE_ATTRIBUTES_FORM_ROUTE = `/settings/${WAREHOUSE_ATTRIBUTES_CODE}/attribute-form`;
export const WAREHOUSE_ATTRIBUTES_FORM_PATH = `${WAREHOUSE_ATTRIBUTES_FORM_ROUTE}/:id?`;
