import { reducer as auth } from '@skunexus/auth';
import { reducer as appReducer } from '@skunexus/utils';
import { reducer as fulfillmentMainTabs } from '@skunexus/fulfillment-main-tabs';
import { reducer as notifications } from '@skunexus/notification';
import { reducer as customFields } from '@skunexus/custom-fields';

import createConfigurableObject from '../../../utils/configurationObject';

export default (app) => {
    app.reducers = app.reducers || createConfigurableObject();
    app.reducers.addMany({
        app: appReducer,
        notifications,
        auth,
        fulfillmentMainTabs,
        customFields,
    });
};
