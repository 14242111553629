import {
    VENDOR_TITLE_PREFIX,
    VENDOR_DETAILS_ROUTE,
    VENDOR_ADDRESSES_GRID_ROUTE,
    VENDOR_PRODUCTS_GRID_ROUTE,
    VENDOR_CONTACTS_GRID_ROUTE,
    VENDOR_PURCHASE_ORDERS_GRID,
} from './consts';

export default () => ({
    overview: {
        id: 'Overview',
        label: 'Overview',
        title: `${VENDOR_TITLE_PREFIX} Overview`,
        link: `${VENDOR_DETAILS_ROUTE}/:vendorId`,
        permission: 'vendors.show',
        order: 10,
    },
    addresses: {
        id: 'Addresses',
        label: 'Addresses',
        title: `${VENDOR_TITLE_PREFIX} Addresses`,
        link: `${VENDOR_ADDRESSES_GRID_ROUTE}/:vendorId`,
        permission: 'vendors - addresses.index',
        order: 20,
    },
    products: {
        id: 'Products',
        label: 'Products',
        title: `${VENDOR_TITLE_PREFIX} Products`,
        link: `${VENDOR_PRODUCTS_GRID_ROUTE}/:vendorId`,
        permission: 'vendors - products.index',
        order: 30,
    },
    contacts: {
        id: 'Contacts',
        label: 'Contacts',
        title: `${VENDOR_TITLE_PREFIX} Contacts`,
        link: `${VENDOR_CONTACTS_GRID_ROUTE}/:vendorId`,
        permission: 'vendors - contacts.index',
        order: 50,
    },
    purchaseOrders: {
        id: 'Purchase Orders',
        label: 'Purchase Orders',
        title: `${VENDOR_TITLE_PREFIX} Purchase Orders`,
        link: `${VENDOR_PURCHASE_ORDERS_GRID}/:vendorId`,
        permission: 'purchase orders.index',
        order: 60,
    },
    // DISABLED FOR NOW AS WE DONT USE IT
    // reportsAndPayouts: {
    //     id: 'Reports & Payouts',
    //     label: 'Reports & Payouts',
    //     title: `${VENDOR_TITLE_PREFIX} Reports & Payouts`,
    //     link: `${VENDOR_REPORTS_ROUTE}/:vendorId`,
    //     permission: 'vendors.show',
    //     order: 70,
    // },
});
