export const SETTINGS_CODE = 'settings';
export const SETTINGS_LABEL = 'Settings';
export const SETTINGS_ICON = 'Gear';

// LABELS
export const CURRENCY_LABEL = 'Currency';
export const COMPANY_LABEL = 'Company';
export const EMAIL_NOTIFICATIONS_LABEL = 'Email Notifications';

// ROUTES
export const SETTINGS_ROUTE_PREFIX = 'settings/global';
export const CURRENCY_ROUTE = `/${SETTINGS_ROUTE_PREFIX}/currency`;
export const COMPANY_ROUTE = `/${SETTINGS_ROUTE_PREFIX}/company`;
export const EMAIL_NOTIFICATIONS_ROUTE = `/${SETTINGS_ROUTE_PREFIX}/email-notifcations`;
