import { useState } from 'react';
import { get } from 'lodash';
import * as API from '../api';

const useConfigApi = () => {
    const [config, setConfig] = useState({
        data: {},
        isLoading: false,
        error: null,
    });

    const fetchConfig = async () => {
        setConfig((state) => ({
            ...state,
            isLoading: true,
            error: null,
        }));
        try {
            const result = await API.fetchConfig();

            const data = get(result, 'data.data');

            if (!data) {
                throw new Error(`Couldn't get config`);
            }

            setConfig((state) => ({
                ...state,
                data,
                isLoading: false,
            }));

            return data;
        } catch (e) {
            setConfig((state) => ({
                ...state,
                isLoading: false,
                error: e,
            }));

            return null;
        }
    };

    return [
        {
            ...config,
        },
        { fetchConfig },
    ];
};

export default useConfigApi;
