
export default {
    name: 'fulfillmentAllShipmentsGridPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'fulfillmentAll';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {

            const customGridData = (gridProps) => ({
                ...gridProps,
                config: {
                    ...gridProps.config || {},
                    rowOptions: [...gridProps.config.rowOptions || []].filter(({id})=> id !== 'single-reship'),
                    multiActionsOptions:  [...gridProps.config.multiActionsOptions || []].filter(({id})=> id !== 'multi-reship')
                }
            });

            const routeProps = pageRoute.props;
            pageRoute.props = () => ({
                ...routeProps(),
                customGridData
            });

            app.routes.changeItem(pageName, pageRoute);
        }
    },
};
