// PAGE TITLE
export const TITLE_PREFIX = 'Product Details';
export const INDEX_TITLE = 'Products';

// ROUTES
export const PRODUCTS_ROUTE_PREFIX = 'products';
export const PRODUCTS_INDEX_ROUTE = `/${PRODUCTS_ROUTE_PREFIX}`;
export const PRODUCTS_DETAILS_PATH = `/${PRODUCTS_ROUTE_PREFIX}/overview/:id`;
export const PRODUCTS_DETAILS_ROUTE = `/${PRODUCTS_ROUTE_PREFIX}/overview`;
export const PRODUCTS_STOCK_PATH = `/${PRODUCTS_ROUTE_PREFIX}/stock/:id`;
export const PRODUCTS_STOCK_ROUTE = `/${PRODUCTS_ROUTE_PREFIX}/stock`;
export const PRODUCTS_ALLOCATIONS_PATH = `/${PRODUCTS_ROUTE_PREFIX}/allocations/:id`;
export const PRODUCTS_ALLOCATIONS_ROUTE = `/${PRODUCTS_ROUTE_PREFIX}/allocations`;
export const PRODUCTS_ADJUSTMENTS_PATH = `/${PRODUCTS_ROUTE_PREFIX}/adjustments/:id`;
export const PRODUCTS_ADJUSTMENTS_ROUTE = `/${PRODUCTS_ROUTE_PREFIX}/adjustments`;
export const PRODUCTS_BARCODES_PATH = `/${PRODUCTS_ROUTE_PREFIX}/barcodes/:id`;
export const PRODUCTS_BARCODES_ROUTE = `/${PRODUCTS_ROUTE_PREFIX}/barcodes`;
export const PRODUCTS_RESERVATONS_PATH = `/${PRODUCTS_ROUTE_PREFIX}/reservations/:id`;
export const PRODUCTS_RESERVATONS_ROUTE = `/${PRODUCTS_ROUTE_PREFIX}/reservations`;
export const PRODUCTS_KITTING_PATH = `/${PRODUCTS_ROUTE_PREFIX}/kitting/:id`;
export const PRODUCTS_KITTING_ROUTE = `/${PRODUCTS_ROUTE_PREFIX}/kitting`;
export const PRODUCTS_MEDIA_PATH = `/${PRODUCTS_ROUTE_PREFIX}/media/:id`;
export const PRODUCTS_MEDIA_ROUTE = `/${PRODUCTS_ROUTE_PREFIX}/media`;
export const PRODUCTS_CYCLE_COUNT_PATH = `/${PRODUCTS_ROUTE_PREFIX}/cycle-count/:id`;
export const PRODUCTS_CYCLE_COUNT_ROUTE = `/${PRODUCTS_ROUTE_PREFIX}/cycle-count`;
export const PRODUCTS_FORM_PATH = `/${PRODUCTS_ROUTE_PREFIX}/form/:id?`;
export const PRODUCTS_FORM_ROUTE = `/${PRODUCTS_ROUTE_PREFIX}/form`;
export const PRODUCTS_STOCK_FORM_PATH = `/${PRODUCTS_ROUTE_PREFIX}/stock-form/:id`;
export const PRODUCTS_STOCK_FORM_ROUTE = `/${PRODUCTS_ROUTE_PREFIX}/stock-form`;
export const PRODUCTS_INTERNAL_TRANSFER_PATH = `/${PRODUCTS_ROUTE_PREFIX}/stock-internal-transfer/:id`;
export const PRODUCTS_INTERNAL_TRANSFER_ROUTE = `/${PRODUCTS_ROUTE_PREFIX}/stock-internal-transfer`;
