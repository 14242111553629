// PAGE TITLE
export const VENDORS_TITLE_PREFIX = 'Vendors';
export const VENDOR_TITLE_PREFIX = 'Vendor';
export const VENDOR_OVERVIEW_TITLE = 'General Information';

// ROUTES
export const VENDOR_ROUTE_PREFIX = 'vendors';
export const VENDOR_INDEX_ROUTE = `/${VENDOR_ROUTE_PREFIX}`;
export const VENDOR_DETAILS_ROUTE = `/${VENDOR_ROUTE_PREFIX}/overview`;
export const VENDOR_FORM_PATH = `/${VENDOR_ROUTE_PREFIX}/:action(add|edit)/:id?`;
export const VENDOR_CREATE_FORM_ROUTE = `/${VENDOR_ROUTE_PREFIX}/add`;
export const VENDOR_EDIT_FORM_ROUTE = `/${VENDOR_ROUTE_PREFIX}/edit`;

// VENDOR ADDRESSES ROUTES
export const VENDOR_ADDRESSES_GRID_ROUTE = `/${VENDOR_ROUTE_PREFIX}/addresses`;
export const VENDOR_ADDRESSES_FORM_PATH = `/${VENDOR_ROUTE_PREFIX}/addresses/:vendorId/:action(add|edit)/:id?`;
export const VENDOR_ADDRESSES_CREATE_FORM_ROUTE = `/${VENDOR_ROUTE_PREFIX}/addresses/:vendorId/add`;
export const VENDOR_ADDRESSES_EDIT_FORM_ROUTE = `/${VENDOR_ROUTE_PREFIX}/addresses/:vendorId/edit/:id`;

// VENDOR PRODUCTS ROUTES
export const VENDOR_PRODUCTS_GRID_ROUTE = `/${VENDOR_ROUTE_PREFIX}/products`;
export const VENDOR_PRODUCTS_DETAILS_ROUTE = `/${VENDOR_ROUTE_PREFIX}/products/:vendorId/overview/:id`;
export const VENDOR_PRODUCTS_FORM_PATH = `/${VENDOR_ROUTE_PREFIX}/products/:vendorId/:action(add|edit)/:id?`;
export const VENDOR_PRODUCTS_CREATE_FORM_ROUTE = `/${VENDOR_ROUTE_PREFIX}/products/:vendorId/add`;
export const VENDOR_PRODUCTS_EDIT_FORM_ROUTE = `/${VENDOR_ROUTE_PREFIX}/products/:vendorId/edit/:id`;

// VENDOR CONTACTS ROUTES
export const VENDOR_CONTACTS_GRID_ROUTE = `/${VENDOR_ROUTE_PREFIX}/contacts`;
export const VENDOR_CONTACTS_FORM_PATH = `/${VENDOR_ROUTE_PREFIX}/contacts/:vendorId/:action(add|edit)/:id?`;
export const VENDOR_CONTACTS_CREATE_FORM_ROUTE = `/${VENDOR_ROUTE_PREFIX}/contacts/:vendorId/add`;
export const VENDOR_CONTACTS_EDIT_FORM_ROUTE = `/${VENDOR_ROUTE_PREFIX}/contacts/:vendorId/edit/:id`;

export const VENDOR_PURCHASE_ORDERS_GRID = `/${VENDOR_ROUTE_PREFIX}/purchase-orders`;
export const VENDOR_REPORTS_ROUTE = `/${VENDOR_ROUTE_PREFIX}/reports`;
