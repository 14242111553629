export default {
    name: 'fulfillmentShipPickingPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'fulfillmentShipPicking';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {
            pageRoute.loader = () => import('./packages/CustomFulfillmentShipPicking/core');

            app.routes.changeItem(pageName, pageRoute);
        }
    },
};
