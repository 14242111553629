import { CurrentUserModel } from '@skunexus/auth';
import get from 'lodash/get';

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["getVendorId", "getUserId", "getCurrentUser", "getRoles"] }] */
class Model {
    isVendor() {
        return !!this.getVendorId();
    }

    getVendorId() {
        return get(CurrentUserModel.getExtra(), 'vendor_id');
    }

    getUserId() {
        return get(CurrentUserModel.getExtra(), 'user_id');
    }

    getCurrentUser() {
        return CurrentUserModel;
    }

    getRoles() {
        return CurrentUserModel.getRoles();
    }

    hasRole(role) {
        return this.getRoles().includes(role);
    }
}

const UserModel = new Model();

export default UserModel;
