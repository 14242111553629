// PAGE TITLE
export const WAREHOUSES_TITLE_PREFIX = 'Warehouses';
export const WAREHOUSE_TITLE_PREFIX = 'Warehouse';

// ROUTES
export const WAREHOUSES_PREFIX = 'settings/warehouses';
export const WAREHOUSES_GRID_ROUTE = `/${WAREHOUSES_PREFIX}/warehouses`;
export const WAREHOUSES_DETAILS_PATH = `/${WAREHOUSES_PREFIX}/warehouses/:id`;
export const WAREHOUSES_DETAILS_ROUTE = `/${WAREHOUSES_PREFIX}/warehouses`;
export const WAREHOUSES_FORM_PATH = `/${WAREHOUSES_PREFIX}/warehouses/form/:id?`;
export const WAREHOUSES_EDIT_FORM_PATH = `/${WAREHOUSES_PREFIX}/warehouses/form/:id`;
export const WAREHOUSES_FORM_ROUTE = `/${WAREHOUSES_PREFIX}/warehouses/form`;
export const WAREHOUSES_DT_FORM_PATH = `/${WAREHOUSES_PREFIX}/warehouses/dt-form/:id`;
export const WAREHOUSES_DT_FORM_ROUTE = `/${WAREHOUSES_PREFIX}/warehouses/dt-form`;
export const WAREHOUSES_LOCATION_GRID_PATH = `/${WAREHOUSES_PREFIX}/warehouses/:id/locations`;
export const WAREHOUSES_LOCATION_GRID_ROUTE = WAREHOUSES_LOCATION_GRID_PATH;
export const WAREHOUSES_LOCATION_WITH_PRODUCTS_GRID_PATH = `/${WAREHOUSES_PREFIX}/warehouses/:id/locations-products`;
export const WAREHOUSES_LOCATION_WITH_PRODUCTS_GRID_ROUTE = WAREHOUSES_LOCATION_WITH_PRODUCTS_GRID_PATH;

export const DESCRIPTORS_TITLE_PREFIX = 'Descriptors';
export const DESCRIPTOR_TITLE_PREFIX = 'Descriptor';
export const DESCRIPTORS_GRID_ROUTE = `/${WAREHOUSES_PREFIX}/descriptors`;
export const DESCRIPTORS_FORM_PATH = `/${WAREHOUSES_PREFIX}/descriptors/form/:id?`;
export const DESCRIPTORS_FORM_ROUTE = `/${WAREHOUSES_PREFIX}/descriptors/form`;

export const DESCRIPTION_TEMPLATES_TITLE_PREFIX = 'Descriptor Templates';
export const DESCRIPTION_TEMPLATE_TITLE_PREFIX = 'Descriptor Template';
export const DESCRIPTION_TEMPLATES_GRID_ROUTE = `/${WAREHOUSES_PREFIX}/descriptor-templates`;
export const DESCRIPTION_TEMPLATES_FORM_PATH = `/${WAREHOUSES_PREFIX}/descriptor-templates/form/:id?`;
export const DESCRIPTION_TEMPLATES_FORM_ROUTE = `/${WAREHOUSES_PREFIX}/descriptor-templates/form`;

export const CARTS_TITLE_PREFIX = 'Carts';
export const CART_TITLE_PREFIX = 'Cart';
export const CARTS_GRID_ROUTE = `/${WAREHOUSES_PREFIX}/carts`;
export const CARTS_TOTES_GRID_PATH = `/${WAREHOUSES_PREFIX}/cart/:id/totes`;
export const CARTS_TOTES_GRID_ROUTE = CARTS_TOTES_GRID_PATH;
export const CARTS_FORM_PATH = `/${WAREHOUSES_PREFIX}/carts/form/:id?`;
export const CARTS_FORM_ROUTE = `/${WAREHOUSES_PREFIX}/carts/form`;

export const TOTES_TITLE_PREFIX = 'Totes';
export const TOTE_TITLE_PREFIX = 'Tote';
export const TOTES_GRID_ROUTE = `/${WAREHOUSES_PREFIX}/totes`;
export const TOTES_FORM_PATH = `/${WAREHOUSES_PREFIX}/totes/form/:id?`;
export const TOTES_FORM_ROUTE = `/${WAREHOUSES_PREFIX}/totes/form`;

export const WAREHOUSE_CONFIGURATION_TITLE_PREFIX = 'Warehouse Configurations';
export const WAREHOUSE_CONFIGURATION_GRID_ROUTE = `/${WAREHOUSES_PREFIX}/warehouse-configurations`;
export const WAREHOUSE_CONFIGURATION_FORM_PATH = `/${WAREHOUSES_PREFIX}/warehouse-configurations/form/:id?`;
export const WAREHOUSE_CONFIGURATION_FORM_ROUTE = `/${WAREHOUSES_PREFIX}/warehouse-configurations/form`;
