import { TITLE_PREFIX, CYCLE_GROUP_ASSIGNMENT_ROUTE, CYCLE_GROUP_MISSING_ASSIGNMENT_ROUTE } from './consts';

export default () => ({
    cycleCountMissingAssignments: {
        id: 'cycleCountMissingAssignments',
        label: 'Missing Assignments',
        title: `${TITLE_PREFIX} > Missing Assignments`,
        link: CYCLE_GROUP_MISSING_ASSIGNMENT_ROUTE,
        permission: ['cycle-counts.count group list'],
        order: 10,
    },
    cycleCountGroupAssignments: {
        id: 'cycleCountGroupAssignments',
        label: 'Group Assignments',
        title: `${TITLE_PREFIX} > Group Assignments`,
        link: CYCLE_GROUP_ASSIGNMENT_ROUTE,
        permission: ['cycle-counts.count group list'],
        order: 20,
    },
});
