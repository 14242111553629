import values from 'lodash/values';
import { withTabs } from '@skunexus/component-catalog';

import UserModel from '../../../../UserModel';
import { filterAccessibleItems } from '../../../../utils/helpers';
import withTabsLayoutWrapper from '../../../../utils/withTabsLayoutWrapper';

const withVendorTabsWrapper = (app) => (Component) => {
    const tabs = UserModel.isVendor() ? [] : filterAccessibleItems(values(app.vendorTabs.getAll()));
    return withTabsLayoutWrapper((props) => withTabs(tabs)(Component)(props));
};

export default withVendorTabsWrapper;
