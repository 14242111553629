import values from 'lodash/values';
import { withTabs } from '@skunexus/component-catalog';
import { filterAccessibleItems } from '../../../../utils/helpers';
import withTabsLayoutWrapper from '../../../../utils/withTabsLayoutWrapper';

const withProductsTabsWrapper = (app) => (Component) =>
    withTabsLayoutWrapper((props) =>
        withTabs(filterAccessibleItems(values(app.productDetailsTabs.getAll())))(Component)(props),
    );

export default withProductsTabsWrapper;
