import {
    CUSTOMER_ATTRIBUTES_FORM_PATH,
    CUSTOMER_ATTRIBUTES_FORM_ROUTE,
    CUSTOMER_ATTRIBUTES_FORM_TITLE,
    CUSTOMER_ATTRIBUTES_GRID_ROUTE,
    PO_ATTRIBUTES_FORM_PATH,
    PO_ATTRIBUTES_FORM_ROUTE,
    PO_ATTRIBUTES_FORM_TITLE,
    PO_ATTRIBUTES_GRID_ROUTE,
    PRODUCT_ATTRIBUTES_FORM_PATH,
    PRODUCT_ATTRIBUTES_FORM_ROUTE,
    PRODUCT_ATTRIBUTES_FORM_TITLE,
    PRODUCT_ATTRIBUTES_GRID_ROUTE,
    ORDER_ATTRIBUTES_FORM_PATH,
    ORDER_ATTRIBUTES_FORM_ROUTE,
    ORDER_ATTRIBUTES_FORM_TITLE,
    ORDER_ATTRIBUTES_GRID_ROUTE,
    USER_ATTRIBUTES_FORM_PATH,
    USER_ATTRIBUTES_FORM_ROUTE,
    USER_ATTRIBUTES_FORM_TITLE,
    USER_ATTRIBUTES_GRID_ROUTE,
    VENDOR_ATTRIBUTES_FORM_PATH,
    VENDOR_ATTRIBUTES_FORM_ROUTE,
    VENDOR_ATTRIBUTES_FORM_TITLE,
    VENDOR_ATTRIBUTES_GRID_ROUTE,
    VENDOR_PRODUCT_ATTRIBUTES_FORM_PATH,
    VENDOR_PRODUCT_ATTRIBUTES_FORM_ROUTE,
    VENDOR_PRODUCT_ATTRIBUTES_FORM_TITLE,
    VENDOR_PRODUCT_ATTRIBUTES_GRID_ROUTE,
    WAREHOUSE_ATTRIBUTES_FORM_PATH,
    WAREHOUSE_ATTRIBUTES_FORM_ROUTE,
    WAREHOUSE_ATTRIBUTES_FORM_TITLE,
    WAREHOUSE_ATTRIBUTES_GRID_ROUTE,
} from './consts';

import withSettingsTabsWrapper from '../settings/components/withSettingsTabsWrapper';

export default (app) => ({
    settingsProductAttributes: {
        name: 'settingsCustomAttributesProduct',
        path: app.settingsAttributesTabs.getItem('productAttributes').link,
        title: app.settingsAttributesTabs.getItem('productAttributes').title,
        permission: app.settingsAttributesTabs.getItem('productAttributes').permission,
        breadcrumbConfig: {
            name: `${app.settingsAttributesTabs.getItem('productAttributes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: PRODUCT_ATTRIBUTES_FORM_ROUTE,
            domain: 'product',
            entityName: 'Product',
            basePath: 'products',
        }),
        loader: () => import('@skunexus/settings-custom-attributes-product'),
        render: withSettingsTabsWrapper(app),
    },
    settingsProductAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: PRODUCT_ATTRIBUTES_FORM_PATH,
        title: PRODUCT_ATTRIBUTES_FORM_TITLE,
        breadcrumbConfig: { title: 'Add Product Attribute', isReplaceble: true },
        props: () => ({
            indexPageRoute: PRODUCT_ATTRIBUTES_GRID_ROUTE,
            domain: 'product',
            entityName: 'Product',
            basePath: 'products',
        }),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
    },
    settingsOrderAttributes: {
        name: 'settingsCustomAttributesOrder',
        path: app.settingsAttributesTabs.getItem('orderAttributes').link,
        title: app.settingsAttributesTabs.getItem('orderAttributes').title,
        permission: app.settingsAttributesTabs.getItem('orderAttributes').permission,
        breadcrumbConfig: {
            title: `${app.settingsAttributesTabs.getItem('orderAttributes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: ORDER_ATTRIBUTES_FORM_ROUTE,
            domain: 'order',
            entityName: 'Order',
            basePath: 'orders',
        }),
        loader: () => import('@skunexus/settings-custom-attributes-order'),
        render: withSettingsTabsWrapper(app),
    },
    settingsOrderAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: ORDER_ATTRIBUTES_FORM_PATH,
        title: ORDER_ATTRIBUTES_FORM_TITLE,

        breadcrumbConfig: { title: 'Add Order Attributes', isReplaceble: true },
        props: () => ({
            indexPageRoute: ORDER_ATTRIBUTES_GRID_ROUTE,
            domain: 'order',
            entityName: 'Order',
            basePath: 'orders',
        }),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
    },
    settingsPOAttributes: {
        name: 'settingsCustomAttributesPurchaseOrder',
        path: app.settingsAttributesTabs.getItem('poAttributes').link,
        title: app.settingsAttributesTabs.getItem('poAttributes').title,
        permission: app.settingsAttributesTabs.getItem('poAttributes').permission,
        breadcrumbConfig: {
            title: `${app.settingsAttributesTabs.getItem('poAttributes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: PO_ATTRIBUTES_FORM_ROUTE,
            domain: 'purchaseOrder',
            entityName: 'Purchase Order',
            basePath: 'purchase-order',
        }),
        loader: () => import('@skunexus/settings-custom-attributes-purchase-order'),
        render: withSettingsTabsWrapper(app),
    },
    settingsPOAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: PO_ATTRIBUTES_FORM_PATH,
        title: PO_ATTRIBUTES_FORM_TITLE,
        breadcrumbConfig: { title: 'Add Purchase Order Attribute', isReplaceble: true },
        props: () => ({
            indexPageRoute: PO_ATTRIBUTES_GRID_ROUTE,
            domain: 'purchaseOrder',
            entityName: 'Purchase Order',
            basePath: 'purchase-order',
        }),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
    },
    settingsVendorAttributes: {
        name: 'settingsCustomAttributesVendor',
        path: app.settingsAttributesTabs.getItem('vendorAttributes').link,
        title: app.settingsAttributesTabs.getItem('vendorAttributes').title,
        permission: app.settingsAttributesTabs.getItem('vendorAttributes').permission,
        breadcrumbConfig: {
            name: `${app.settingsAttributesTabs.getItem('vendorAttributes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: VENDOR_ATTRIBUTES_FORM_ROUTE,
            domain: 'vendor',
            entityName: 'Vendor',
            basePath: 'vendors',
        }),
        loader: () => import('@skunexus/settings-custom-attributes-vendor'),
        render: withSettingsTabsWrapper(app),
    },
    settingsVendorAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: VENDOR_ATTRIBUTES_FORM_PATH,
        title: VENDOR_ATTRIBUTES_FORM_TITLE,
        breadcrumbConfig: { title: 'Add Vendor Attributes', isReplaceble: true },
        props: () => ({
            indexPageRoute: VENDOR_ATTRIBUTES_GRID_ROUTE,
            domain: 'vendor',
            entityName: 'Vendor',
            basePath: 'vendors',
        }),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
    },
    settingsUserAttributes: {
        name: 'settingsCustomAttributesUser',
        path: app.settingsAttributesTabs.getItem('userAttributes').link,
        title: app.settingsAttributesTabs.getItem('userAttributes').title,
        permission: app.settingsAttributesTabs.getItem('userAttributes').permission,
        breadcrumbConfig: {
            title: `${app.settingsAttributesTabs.getItem('userAttributes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: USER_ATTRIBUTES_FORM_ROUTE,
            domain: 'user',
            entityName: 'User',
            basePath: 'users',
        }),
        loader: () => import('@skunexus/settings-custom-attributes-user'),
        render: withSettingsTabsWrapper(app),
    },
    settingsUserAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: USER_ATTRIBUTES_FORM_PATH,
        title: USER_ATTRIBUTES_FORM_TITLE,
        breadcrumbConfig: { title: 'Add User Attributes', isReplaceble: true },
        props: () => ({
            indexPageRoute: USER_ATTRIBUTES_GRID_ROUTE,
            domain: 'user',
            entityName: 'User',
            basePath: 'users',
        }),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
    },
    settingsWarehouseAttributes: {
        name: 'settingsCustomAttributesWarehouse',
        path: app.settingsAttributesTabs.getItem('warehouseAttributes').link,
        title: app.settingsAttributesTabs.getItem('warehouseAttributes').title,
        permission: app.settingsAttributesTabs.getItem('warehouseAttributes').permission,
        breadcrumbConfig: {
            name: `${app.settingsAttributesTabs.getItem('warehouseAttributes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: WAREHOUSE_ATTRIBUTES_FORM_ROUTE,
            domain: 'warehouse',
            entityName: 'Warehouse',
            basePath: 'warehouses',
        }),
        loader: () => import('@skunexus/settings-custom-attributes-warehouse'),
        render: withSettingsTabsWrapper(app),
    },
    settingsWarehouseAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: WAREHOUSE_ATTRIBUTES_FORM_PATH,
        title: WAREHOUSE_ATTRIBUTES_FORM_TITLE,
        breadcrumbConfig: { title: 'Add Warehouse Attributes', isReplaceble: true },
        props: () => ({
            indexPageRoute: WAREHOUSE_ATTRIBUTES_GRID_ROUTE,
            domain: 'warehouse',
            entityName: 'Warehouse',
            basePath: 'warehouses',
        }),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
    },
    settingsCustomerAttributes: {
        name: 'settingsCustomAttributesCustomer',
        path: app.settingsAttributesTabs.getItem('customerAttributes').link,
        title: app.settingsAttributesTabs.getItem('customerAttributes').title,
        permission: app.settingsAttributesTabs.getItem('customerAttributes').permission,
        breadcrumbConfig: {
            name: `${app.settingsAttributesTabs.getItem('customerAttributes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: CUSTOMER_ATTRIBUTES_FORM_ROUTE,
            domain: 'customer',
            entityName: 'Customer',
            basePath: 'customers',
        }),
        loader: () => import('@skunexus/settings-custom-attributes-customer'),
        render: withSettingsTabsWrapper(app),
    },
    settingsCustomerAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: CUSTOMER_ATTRIBUTES_FORM_PATH,
        title: CUSTOMER_ATTRIBUTES_FORM_TITLE,
        breadcrumbConfig: { title: 'Add Customer Attributes', isReplaceble: true },
        props: () => ({
            indexPageRoute: CUSTOMER_ATTRIBUTES_GRID_ROUTE,
            domain: 'customer',
            entityName: 'Customer',
            basePath: 'customers',
        }),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
    },
    settingsVendorProductAttributes: {
        name: 'settingsCustomAttributesVendorProducts',
        path: app.settingsAttributesTabs.getItem('vendorProductAttributes').link,
        title: app.settingsAttributesTabs.getItem('vendorProductAttributes').title,
        permission: app.settingsAttributesTabs.getItem('vendorProductAttributes').permission,
        breadcrumbConfig: {
            name: `${app.settingsAttributesTabs.getItem('vendorProductAttributes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: VENDOR_PRODUCT_ATTRIBUTES_FORM_ROUTE,
            domain: 'vendorProduct',
            entityName: 'Vendor Product',
            basePath: 'vendor-products',
        }),
        loader: () => import('@skunexus/settings-custom-attributes-vendor-product'),
        render: withSettingsTabsWrapper(app),
    },
    settingsVendorProductAttributesForm: {
        name: 'settingsVendorProductAttributesForm',
        path: VENDOR_PRODUCT_ATTRIBUTES_FORM_PATH,
        title: VENDOR_PRODUCT_ATTRIBUTES_FORM_TITLE,
        breadcrumbConfig: { title: 'Add Vendor Product Attributes', isReplaceble: true },
        props: () => ({
            indexPageRoute: VENDOR_PRODUCT_ATTRIBUTES_GRID_ROUTE,
            domain: 'vendorProduct',
            entityName: 'Vendor Product',
            basePath: 'vendor-products',
        }),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
    },
});
