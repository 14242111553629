import createConfigurableObject from '../../../utils/configurationObject';

export default (app) => {
    app.headerMenuItems = app.headerMenuItems || createConfigurableObject();
    app.headerMenuItems.addMany({
        logout: {
            hasBorder: true,
            label: 'Logout',
            icon: 'ArrowRightCircle',
            link: '/logout',
            order: 100,
        },
    });
};
