// PAGE TITLE
export const TITLE_PREFIX = 'Receive Inventory';
export const PUT_AWAY_TITLE_PREFIX = 'Put Away';

// ROUTES
export const RI_ROUTE_PREFIX = 'receive-inventory';
export const RI_INDEX_ROUTE = `/${RI_ROUTE_PREFIX}/main`;
export const RI_JUST_ADDING_ROUTE = `/${RI_ROUTE_PREFIX}/just-adding`;
export const RI_ENTER_LOCATION_PATH = `/${RI_ROUTE_PREFIX}/enter-location/:id/:stockId`;
export const RI_ENTER_LOCATION_ROUTE = `/${RI_ROUTE_PREFIX}/enter-location`;
export const RI_ITEMS_INDEX_ROUTE = `/${RI_ROUTE_PREFIX}/items`;
export const RI_PUT_AWAY_INDEX_ROUTE = `/${RI_ROUTE_PREFIX}/put-away`;
export const RI_PUT_AWAY_PATH = `/${RI_ROUTE_PREFIX}/put-away/:id`;
export const RI_PUT_AWAY_ROUTE = `/${RI_ROUTE_PREFIX}/put-away`;
export const RI_PUT_AWAY_FINALIZED_INDEX_ROUTE = `/${RI_ROUTE_PREFIX}/put-away-finalized`;
export const RI_PUT_AWAY_DETAILS_PATH = `/${RI_ROUTE_PREFIX}/put-away/details/:id`;
export const RI_PUT_AWAY_DETAILS_ROUTE = `/${RI_ROUTE_PREFIX}/put-away/details`;
export const RI_PUT_AWAY_CANCELLED_INDEX_ROUTE = `/${RI_ROUTE_PREFIX}/put-away-cancelled`;
