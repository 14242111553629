import { VENDOR_DETAILS_ROUTE } from './consts';

export default () => ({
    overview: (vendorId) => ({
        icon: 'Shop',
        label: 'General Information',
        link: `${VENDOR_DETAILS_ROUTE}/${vendorId}`,
        order: 10,
    }),
});
