import { useEffect } from 'react';

import get from 'lodash/get';
import { CurrentUserModel } from '@skunexus/auth';
import app from '../../config';

const smartlookEnabled = get(app.general.getItem('smartlook'), 'enabled', false);

export default () => {
    useEffect(() => {
        const userEmail = get(CurrentUserModel.getExtra(), 'email');

        if (smartlookEnabled && userEmail) {
            try {
                window.smartlook('identify', userEmail);
            } catch (error) {
                /* eslint-disable-next-line no-console */
                console.error(error);
            }
        }
    }, []);
    return null;
};
