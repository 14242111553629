import { TITLE_PREFIX, CYCLE_ACTIVE_ROUTE, CYCLE_MANAGER_ROUTE, CYCLE_GROUP_MISSING_ASSIGNMENT_ROUTE } from './consts';

export default () => ({
    label: TITLE_PREFIX,
    link: CYCLE_ACTIVE_ROUTE,
    icon: 'ArrowRepeat',
    permission: [
        'cycle-counts.index',
        'cycle-counts.complete item',
        'cycle-counts.set item',
        'cycle-counts.transfer item',
        'cycle-counts.count group list',
    ],
    order: 50,
    items: [
        {
            label: TITLE_PREFIX,
            link: CYCLE_ACTIVE_ROUTE,
            icon: 'ArrowRepeat',
            permission: ['cycle-counts.index'],
        },
        {
            label: 'Manager Review',
            link: CYCLE_MANAGER_ROUTE,
            icon: 'Intersect',
            permission: ['cycle-counts.complete item', 'cycle-counts.set item', 'cycle-counts.transfer item'],
        },
        {
            label: 'Group Assignments',
            link: CYCLE_GROUP_MISSING_ASSIGNMENT_ROUTE,
            icon: 'UiChecksGrid',
            permission: ['cycle-counts.count group list'],
        },
    ],
});
