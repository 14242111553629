import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { VENDOR_OVERVIEW_TITLE } from './consts';
import createConfigurableObject from '../../../utils/configurationObject';

const routesToCopy = [
    'vendorContactForm',
    'vendorContacts',
    'vendorAddressesForm',
    'vendorAddresses',
    'vendorOverview',
    'vendorProducts',
    'vendorProductsForm',
    'vendorProductsDetails',
    'vendorPurchaseOrders',
    'vendorForm',
];

export default (app) => {
    const vendorTabs = app.vendorTabs.getAll();
    app.vendorRoutes = app.vendorRoutes || createConfigurableObject();

    if (vendorTabs.reportsAndPayouts) {
        // DISABLED FOR NOW AS WE DONT USE IT
        // app.vendorRoutes.addItem('vendorReports', {
        //     path: vendorTabs.reportsAndPayouts.link,
        //     title: vendorTabs.reportsAndPayouts.title,
        //     permission: vendorTabs.reportsAndPayouts.permission,
        //       breadcrumbConfig: {
        //         title: 'Reports & Payouts',
        //         clearPath: true,
        //     },
        //     props: (state) => ({
        //         tabs: UserModel.isVendor() ? [] : filterAccessibleItems(values(vendorTabs)),
        //         idNameMap: selectors.getIDNameMap(state.app),
        //         isVendorMode: UserModel.isVendor(),
        //     }),
        //     loader: () => {
        //         return import('@skunexus/vendor-reports-payouts').then((ModuleConfig) => {
        //             return {
        //                 ...ModuleConfig,
        //                 Module: ModuleConfig.VendorModule,
        //             };
        //         });
        //     },
        // });
    }

    forEach(routesToCopy, (routeName) => {
        const route = { ...app.routes.getItem(routeName) };
        if (!isEmpty(route)) {
            const isTabBreadcrumbConfig = get(route, 'breadcrumbConfig.isTab', false);

            if (isTabBreadcrumbConfig) {
                route.breadcrumbConfig = {
                    title: get(route, 'breadcrumbConfig.title', ''),
                    clearPath: true,
                };
            }
            app.vendorRoutes.addItem(routeName, route);
        }
    });

    const vendorOverviewRouter = app.vendorRoutes.getItem('vendorOverview');
    if (vendorOverviewRouter) {
        const vendorOverviewProps = vendorOverviewRouter.props;
        vendorOverviewRouter.breadcrumbConfig = {
            title: VENDOR_OVERVIEW_TITLE,
            isOverview: false,
            clearPath: true,
        };
        vendorOverviewRouter.props = (state) => ({
            ...vendorOverviewProps(state),
            tabs: [],
        });

        app.vendorRoutes.changeItem('vendorOverview', vendorOverviewRouter);
    }
};
