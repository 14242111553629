import createConfigurableObject from '../../../utils/configurationObject';
import smartlookConfig from './smartlook';
import localizejsConfig from './localizejs';

export default (app) => {
    app.general = app.general || createConfigurableObject();

    smartlookConfig(app);
    localizejsConfig(app);
};
