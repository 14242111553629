import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { provideRouteFactory } from '@skunexus/utils';
import { actions } from '@skunexus/auth';

/*
  Setup below
*/

export const LOGOUT_PATH = '/logout';

class Logout extends Component {
    componentDidMount() {
        const { logout } = this.props;
        logout();
    }

    render() {
        return null;
    }
}

Logout.propTypes = {
    logout: PropTypes.func.isRequired,
};

const storeSelector = (state) => state;

/*
  Setup above
*/

const dispatchProps = {
    logout: actions.logout.TRIGGER,
};

const Route = provideRouteFactory(null, dispatchProps);

export default function (props) {
    return <Route {...props} path={LOGOUT_PATH} component={Logout} storeSelector={storeSelector} />;
}
