import React from 'react';

function withTabsLayoutWrapper(Component) {
    function WrappedField(props) {
        return (
            <div className="my-3">
                <Component {...props} />
            </div>
        );
    }

    return WrappedField;
}
export default withTabsLayoutWrapper;
