import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';

const routesToCopy = ['purchaseOrderOverview', 'purchaseOrderPrint'];

export default (app) => {
    forEach(routesToCopy, (routeName) => {
        const route = { ...app.routes.getItem(routeName) };
        if (!isEmpty(route)) {
            app.vendorRoutes.addItem(routeName, route);
        }
    });

    const purchaseOrderOverviewRouter = app.vendorRoutes.getItem('purchaseOrderOverview');
    if (purchaseOrderOverviewRouter) {
        const purchaseOrderOverviewProps = purchaseOrderOverviewRouter.props;
        purchaseOrderOverviewRouter.props = (state) => ({
            ...purchaseOrderOverviewProps(state),
            permissions: {
                allowedStates: app.general.getItem('modules.purchaseOrder.vendorStates') || [],
            },
        });

        app.vendorRoutes.changeItem('purchaseOrderOverview', purchaseOrderOverviewRouter);
    }
};
