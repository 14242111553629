// PAGE TITLE
export const TITLE_PREFIX = 'Integrations';

// ROUTES
export const INTEGRATIONS_ROUTE_PREFIX = 'settings/integrations';
export const INTEGRATIONS_INDEX_ROUTE = `/${INTEGRATIONS_ROUTE_PREFIX}`;
export const INTEGRATIONS_DETAILS_PATH = `/${INTEGRATIONS_ROUTE_PREFIX}/:id`;
export const INTEGRATIONS_DETAILS_ROUTE = `/${INTEGRATIONS_ROUTE_PREFIX}`;
export const INTEGRATIONS_FORM_PATH = `/${INTEGRATIONS_ROUTE_PREFIX}/:action(add|edit)/:id?`;
export const INTEGRATIONS_CREATE_FORM_ROUTE = `/${INTEGRATIONS_ROUTE_PREFIX}/add`;
export const INTEGRATIONS_EDIT_FORM_ROUTE = `/${INTEGRATIONS_ROUTE_PREFIX}/edit`;

export const INTEGRATIONS_AUTHORIZE_COMPLETED_ROUTE = `/${INTEGRATIONS_ROUTE_PREFIX}/authorize/completed`;

export const WEBHOOK_EVENTS_INDEX_PATH = `/${INTEGRATIONS_ROUTE_PREFIX}/webhook/events/:id`;
export const WEBHOOK_EVENTS_INDEX_ROUTE = `/${INTEGRATIONS_ROUTE_PREFIX}/webhook/events`;
export const WEBHOOK_EVENTS_DETAILS_PATH = `/${INTEGRATIONS_ROUTE_PREFIX}/webhook/events/:integrationId/event/:id`;
export const WEBHOOK_EVENTS_DETAILS_ROUTE = `/${INTEGRATIONS_ROUTE_PREFIX}/webhook/events/:integrationId/event`;
