import {
    SHIPMENT_TITLE_PREFIX,
    SHIPMENT_DETAILS_PATH,
    CLASS_CODES_TITLE_PREFIX,
    CLASS_CODES_INDEX_ROUTE,
    CLASS_CODES_FORM_PATH,
    CLASS_CODES_FORM_ROUTE,
    MAPPER_TITLE_PREFIX,
    MAPPER_INDEX_ROUTE,
    MAPPER_FORM_PATH,
    MAPPER_CREATE_FORM_ROUTE,
    MAPPER_EDIT_FORM_ROUTE,
    CARRIERS_TITLE_PREFIX,
    CARRIERS_INDEX_ROUTE,
    CARRIERS_FORM_PATH,
    CARRIERS_FORM_ROUTE,
    PARCELS_TITLE_PREFIX,
    PARCELS_INDEX_ROUTE,
    PARCELS_FORM_PATH,
    PARCELS_FORM_ROUTE,
    SHIPPING_SETTINGS_TITLE_PREFIX,
    SHIPPING_SETTINGS_ROUTE,
} from './consts';
import { SHIP_DASHBOARD_ROUTE, SHIP_DETAILS_ROUTE } from '../fulfillment/consts';
import { ORDERS_DETAILS_ROUTE } from '../orders/consts';
import { PRODUCTS_DETAILS_ROUTE } from '../products/consts';
import withSettingsTabsWrapper from '../settings/components/withSettingsTabsWrapper';

export default (app) => ({
    shipment: {
        path: SHIPMENT_DETAILS_PATH,
        title: SHIPMENT_TITLE_PREFIX,
        permission: ['fulfillments.show'],
        breadcrumbConfig: {
            name: `${SHIPMENT_TITLE_PREFIX}`,
        },
        props: () => ({
            fulfillmentDashboardPageRoute: SHIP_DASHBOARD_ROUTE,
            fulfillmentDetailsPageRoute: SHIP_DETAILS_ROUTE,
            orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
            productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
        }),
        loader: () => import('@skunexus/shipment'),
    },
    classCodes: {
        path: app.settingsShippingTabs.getItem('classCodes').link,
        title: app.settingsShippingTabs.getItem('classCodes').label,
        permission: app.settingsShippingTabs.getItem('classCodes').permission,
        breadcrumbConfig: {
            title: `${app.settingsShippingTabs.getItem('classCodes').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: CLASS_CODES_FORM_ROUTE,
        }),
        loader: () => import('@skunexus/class-codes'),
        render: withSettingsTabsWrapper(app),
    },
    classCodesForm: {
        path: CLASS_CODES_FORM_PATH,
        title: `${CLASS_CODES_TITLE_PREFIX} Form`,
        breadcrumbConfig: { title: 'Add Class Code', isReplaceble: true },
        props: () => ({
            indexPageRoute: CLASS_CODES_INDEX_ROUTE,
        }),
        loader: () => import('@skunexus/class-codes-form'),
    },
    shipingOptionsMapper: {
        path: app.settingsShippingTabs.getItem('optionMappers').link,
        title: app.settingsShippingTabs.getItem('optionMappers').label,
        permission: app.settingsShippingTabs.getItem('optionMappers').permission,
        breadcrumbConfig: {
            title: `${app.settingsShippingTabs.getItem('optionMappers').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            addFormPageRoute: MAPPER_CREATE_FORM_ROUTE,
            editFormPageRoute: MAPPER_EDIT_FORM_ROUTE,
        }),
        loader: () => import('@skunexus/settings-so-mappers'),
        render: withSettingsTabsWrapper(app),
    },
    shipingOptionsMapperForm: {
        path: MAPPER_FORM_PATH,
        title: `${MAPPER_TITLE_PREFIX} Form`,
        breadcrumbConfig: { title: 'Add Mapper', isReplaceble: true },
        props: () => ({
            indexPageRoute: MAPPER_INDEX_ROUTE,
        }),
        loader: () => import('@skunexus/mapper-form'),
    },
    carrierForm: {
        path: CARRIERS_FORM_PATH,
        title: `${CARRIERS_TITLE_PREFIX} Form`,
        breadcrumbConfig: { title: 'Add Carrier', isReplaceble: true },
        props: () => ({
            indexPageRoute: CARRIERS_INDEX_ROUTE,
        }),
        loader: () => import('@skunexus/carrier-form'),
    },
    carrierServices: {
        path: app.settingsShippingTabs.getItem('carriers').link,
        title: app.settingsShippingTabs.getItem('carriers').label,
        permission: app.settingsShippingTabs.getItem('carriers').permission,
        breadcrumbConfig: {
            title: `${app.settingsShippingTabs.getItem('carriers').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: CARRIERS_FORM_ROUTE,
        }),
        loader: () => import('@skunexus/carrier-grid'),
        render: withSettingsTabsWrapper(app),
    },
    parcels: {
        path: app.settingsShippingTabs.getItem('parcels').link,
        title: app.settingsShippingTabs.getItem('parcels').label,
        permission: app.settingsShippingTabs.getItem('parcels').permission,
        breadcrumbConfig: {
            title: `${app.settingsShippingTabs.getItem('parcels').label}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: PARCELS_FORM_ROUTE,
        }),
        loader: () => import('@skunexus/parcels'),
        render: withSettingsTabsWrapper(app),
    },
    parcelsForm: {
        path: PARCELS_FORM_PATH,
        title: `${PARCELS_TITLE_PREFIX} Form`,
        breadcrumbConfig: { title: 'Add Parcel', isReplaceble: true },
        props: () => ({
            indexPageRoute: PARCELS_INDEX_ROUTE,
        }),
        loader: () => import('@skunexus/parcel-form'),
    },
    shippingSettings: {
        path: SHIPPING_SETTINGS_ROUTE,
        title: SHIPPING_SETTINGS_TITLE_PREFIX,
        breadcrumbConfig: {
            title: `Shipping ${SHIPPING_SETTINGS_TITLE_PREFIX}`,
            isTab: true,
            clearPath: true,
        },
        props: () => ({}),
        loader: () => import('@skunexus/shipping-settings'),
        render: withSettingsTabsWrapper(app),
    },
});
