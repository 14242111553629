import createConfigurableObject from '../../../utils/configurationObject';
import routes from './routes';
import sideMenuItem from './sideMenu';
import tabs from './tabs';
import groupTabs from './groupTabs';

export default (app) => {
    app.cycleCountTabs = app.cycleCountTabs || createConfigurableObject();
    app.cycleCountTabs.addMany(tabs(app));

    app.cycleCountGroupsTabs = app.cycleCountGroupsTabs || createConfigurableObject();
    app.cycleCountGroupsTabs.addMany(groupTabs(app));

    app.routes.addMany(routes(app));
    app.sideMenuItems.addItem('cycleCount', sideMenuItem(app));
};
