import { PICKUP_TITLE_PREFIX, PICKUP_WAVES_ROUTE, PICKUP_WAVES_HISTORY_ROUTE } from './consts';

export default () => ({
    waves: {
        id: 'Waves',
        label: 'Waves',
        title: `${PICKUP_TITLE_PREFIX} > Waves`,
        link: PICKUP_WAVES_ROUTE,
        permission: 'fulfillments - waves.index',
        order: 10,
    },
    wavesHistory: {
        id: 'Waves History',
        label: 'Waves History',
        title: `${PICKUP_TITLE_PREFIX} > Waves History`,
        link: PICKUP_WAVES_HISTORY_ROUTE,
        permission: 'fulfillments - waves.index',
        order: 20,
    },
});
