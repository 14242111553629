import values from 'lodash/values';
import createConfigurableObject from '../../../utils/configurationObject';
import { firstAllowedLink, combinePermissions } from '../../../utils/helpers';

import settingsTabs from './settingsTabs';
import routes from './routes';

export default (app) => {
    app.settingsShippingTabs = app.settingsShippingTabs || createConfigurableObject();
    app.settingsShippingTabs.addMany(settingsTabs(app));

    app.settingsTabs.addItem('shipping', {
        label: 'Shipping',
        link: () => firstAllowedLink(values(app.settingsShippingTabs.getAll())),
        permission: () => combinePermissions(values(app.settingsShippingTabs.getAll())),
        order: 2,
        children: values(app.settingsShippingTabs.getAll()),
    });

    app.routes.addMany(routes(app));
};
