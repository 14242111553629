export default {
    name: 'fulfillmentPickupPickingPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'pickupPicking';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {
            pageRoute.loader = () => import('./packages/CustomFulfillmentPickupPicking/core');

            app.routes.changeItem(pageName, pageRoute);
        }
    },
};
