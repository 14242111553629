import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import appConfig from './config';
import './utils/setupAxiosConfig';

function main() {
    document.title = appConfig.general.getItem('name');
    ReactDOM.render(<App />, document.getElementById('root'));
}

main();
