import { RI_INDEX_ROUTE } from '../../modules/receiveInventory/consts';
import { PRODUCTS_DETAILS_ROUTE } from '../../modules/products/consts';

export default {
    name: 'justAddingPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'receiveInventoryJai';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {
            const routeProps = pageRoute.props;
            pageRoute.props = () => ({
                ...routeProps(),
                routes: {
                    indexPageRoute: RI_INDEX_ROUTE,
                    productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                },
                mode: {
                    withLocations: true,
                },
            });

            app.routes.changeItem(pageName, pageRoute);
        }
    },
};
