import React from 'react';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import app from '../../config';

const smartlookEnabled = get(app.general.getItem('smartlook'), 'enabled', false);
const smartlookId = get(app.general.getItem('smartlook'), 'id');

export default function () {
    if (smartlookEnabled && smartlookId) {
        return (
            <Helmet
                script={[
                    {
                        type: 'text/javascript',
                        innerHTML: `window.smartlook||(function(d) { var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0]; var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript'; c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c); })(document); smartlook('init', '${smartlookId}');`,
                    },
                ]}
            />
        );
    }

    return null;
}
