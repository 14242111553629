export default (app) => {
    app.general.addItem('localizejs', {
        enabled: false,
        appKey: null,
        config: {
            rememberLanguage: true,
            disableWidget: true,
        },
    });
};
