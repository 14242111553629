import React from 'react';
import ConfigContextProvider from './ConfigContext';

const contextProviders = [ConfigContextProvider];

function AppContextProvider({ children }) {
    return contextProviders.reduceRight((memo, ContextProvider) => <ContextProvider>{memo}</ContextProvider>, children);
}

export default AppContextProvider;
