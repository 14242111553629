import { TITLE_PREFIX, RMA_INDEX_ROUTE, RMA_DETAILS_PATH, RMA_DETAILS_ROUTE } from './consts';
import { ORDERS_DETAILS_ROUTE } from '../orders/consts';
import { PRODUCTS_DETAILS_ROUTE } from '../products/consts';
import { EMPTY_TITLE_CRUMB } from '../constants';

export default (app) => {
    app.routes.addMany({
        rmaGrid: {
            path: RMA_INDEX_ROUTE,
            title: TITLE_PREFIX,
            permission: ['rma.index'],
            breadcrumbConfig: { title: `${TITLE_PREFIX}`, clearPath: true },
            props: () => ({
                detailsPageRoute: RMA_DETAILS_ROUTE,
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/rma-grid'),
        },
        rmaDetails: {
            path: RMA_DETAILS_PATH,
            title: `${TITLE_PREFIX} - Details`,
            permission: ['rma.show'],
            breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/rma-details'),
        },
    });
    app.sideMenuItems.addItem('rma', {
        label: TITLE_PREFIX,
        link: RMA_INDEX_ROUTE,
        icon: 'Save',
        permission: 'rma.index',
        order: 50,
    });
};
