import withSettingsTabsWrapper from '../settings/components/withSettingsTabsWrapper';

import { QUEUE_LABEL } from './consts';

export default (app) => ({
    queueFailedJobs: {
        name: 'queueFailedJobs',
        path: app.queueTabs.getItem('failed').link,
        title: app.queueTabs.getItem('failed').title,
        permission: app.queueTabs.getItem('failed').permission,
        breadcrumbConfig: {
            name: `${QUEUE_LABEL} - ${app.queueTabs.getItem('failed').label}`,
            clearPath: true,
        },
        props: () => ({}),
        loader: () => import('@skunexus/queue-failed-grid'),
        render: withSettingsTabsWrapper(app),
    },
    queueSpawnedJobs: {
        name: 'queueSpawnedJobs',
        path: app.queueTabs.getItem('spawned').link,
        title: app.queueTabs.getItem('spawned').title,
        permission: app.queueTabs.getItem('spawned').permission,
        breadcrumbConfig: { title: `${app.queueTabs.getItem('spawned').label}` },
        props: () => ({}),
        loader: () => import('@skunexus/queue-spawned-grid'),
        render: withSettingsTabsWrapper(app),
    },
});
