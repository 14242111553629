// PAGE TITLE
export const CUSTOMERS_TITLE_PREFIX = 'Customer';

// ROUTES
export const CUSTOMERS_ROUTE_PREFIX = 'customers';
export const CUSTOMERS_INDEX_ROUTE = `/${CUSTOMERS_ROUTE_PREFIX}`;
export const CUSTOMERS_DETAILS_PATH = `/${CUSTOMERS_ROUTE_PREFIX}/:id`;
export const CUSTOMERS_DETAILS_ROUTE = `/${CUSTOMERS_ROUTE_PREFIX}`;
export const CUSTOMERS_FORM_PATH = `/${CUSTOMERS_ROUTE_PREFIX}/:action(add|edit)/:id?`;
export const CUSTOMERS_CREATE_FORM_ROUTE = `/${CUSTOMERS_ROUTE_PREFIX}/add`;
export const CUSTOMERS_EDIT_FORM_ROUTE = `/${CUSTOMERS_ROUTE_PREFIX}/edit`;
export const CUSTOMERS_ADDRESS_PATH = `/${CUSTOMERS_ROUTE_PREFIX}/:id/customer-address-form/:action(add|edit)/:addressId?`;
export const CUSTOMERS_CREATE_ADDRESS_ROUTE = `/${CUSTOMERS_ROUTE_PREFIX}/:customerId/customer-address-form/add`;
export const CUSTOMERS_EDIT_ADDRESS_ROUTE = `/${CUSTOMERS_ROUTE_PREFIX}/:customerId/customer-address-form/edit`;
