import backOrderGridPage from './backOrderGridPage';
import fulfillmentAllShipmentsGridPage from './fulfillmentAllShipmentsGridPage';
import fulfillmentPickupGroupPickingPage from './fulfillmentPickupGroupPickingPage';
import fulfillmentPickupDetailsPage from './fulfillmentPickupDetailsPage';
import fulfillmentPickupPackingPage from './fulfillmentPickupPackingPage';
import fulfillmentPickupPickingPage from './fulfillmentPickupPickingPage';
import fulfillmentShipGroupPickingPage from './fulfillmentShipGroupPickingPage';
import fulfillmentShipDetailsPage from './fulfillmentShipDetailsPage';
import fulfillmentShipPackingPage from './fulfillmentShipPackingPage';
import fulfillmentShipPickingPage from './fulfillmentShipPickingPage';
import justAddingPage from './justAddingPage';
import orderDetailsPage from './orderDetailsPage';
import purchaseOrderPrintPage from './purchaseOrderPrintPage';
import printUpdate from './printUpdate';

export default {
    backOrderGridPage,
    fulfillmentAllShipmentsGridPage,
    fulfillmentPickupGroupPickingPage,
    fulfillmentPickupDetailsPage,
    fulfillmentPickupPackingPage,
    fulfillmentPickupPickingPage,
    fulfillmentShipGroupPickingPage,
    fulfillmentShipDetailsPage,
    fulfillmentShipPackingPage,
    fulfillmentShipPickingPage,
    justAddingPage,
    orderDetailsPage,
    purchaseOrderPrintPage,
    printUpdate
};
