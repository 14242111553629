import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useConfig from './useConfig';

const ConfigContext = createContext();

function ConfigContextProvider({ children }) {
    const contextValue = useConfig();
    return <ConfigContext.Provider value={contextValue}>{children}</ConfigContext.Provider>;
}

ConfigContextProvider.propTypes = {
    children: PropTypes.any,
};

ConfigContextProvider.defaultProps = {
    children: null,
};

export default ConfigContextProvider;
export const useConfigContext = () => useContext(ConfigContext);
