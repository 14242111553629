// PAGE TITLE
export const TITLE_PREFIX = 'Order';
export const ORDERS_DECISIONS_TITLE = `${TITLE_PREFIX} Decision Settings`;
export const ORDERS_RULES_TITLE = `${TITLE_PREFIX} Decision Rules Settings`;

// ROUTES
export const ORDERS_ROUTE_PREFIX = 'orders';
export const ORDERS_INDEX_ROUTE = `/${ORDERS_ROUTE_PREFIX}`;

export const ORDERS_FORM_PATH = `/${ORDERS_ROUTE_PREFIX}/:action(add)`;
export const ORDERS_CREATE_FORM_ROUTE = `/${ORDERS_ROUTE_PREFIX}/add`;
export const ORDERS_DETAILS_PATH = `/${ORDERS_ROUTE_PREFIX}/:id`;
export const ORDERS_DETAILS_ROUTE = `/${ORDERS_ROUTE_PREFIX}`;

// SETTINGS ROUTES
export const ORDERS_SETTINGS_ROUTE_PREFIX = 'settings/orders';
export const ORDERS_DECISIONS_ROUTE = `/${ORDERS_SETTINGS_ROUTE_PREFIX}/decision`;

export const ORDERS_RULES_ROUTE = `/${ORDERS_SETTINGS_ROUTE_PREFIX}/rules`;
export const ORDERS_RULES_FORM_PATH = `/${ORDERS_SETTINGS_ROUTE_PREFIX}/rules/:action(add|edit)/:id?`;
export const ORDERS_RULES_CREATE_FORM_ROUTE = `/${ORDERS_SETTINGS_ROUTE_PREFIX}/rules/add`;
export const ORDERS_RULES_EDIT_FORM_ROUTE = `/${ORDERS_SETTINGS_ROUTE_PREFIX}/rules/edit`;
