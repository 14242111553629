import { SHIP_TITLE_PREFIX, SHIP_WAVES_ROUTE, SHIP_WAVES_HISTORY_ROUTE } from './consts';

export default () => ({
    waves: {
        id: 'Waves',
        label: 'Waves',
        title: `${SHIP_TITLE_PREFIX} > Waves`,
        link: SHIP_WAVES_ROUTE,
        permission: 'fulfillments - waves.index',
        order: 10,
    },
    wavesHistory: {
        id: 'Waves History',
        label: 'Waves History',
        title: `${SHIP_TITLE_PREFIX} > Waves History`,
        link: SHIP_WAVES_HISTORY_ROUTE,
        permission: 'fulfillments - waves.index',
        order: 20,
    },
});
