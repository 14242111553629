// PAGE TITLE
export const TITLE_PREFIX = 'Cycle Count';

// ROUTES
export const CYCLE_COUNT_ROUTE_PREFIX = '/cycle-count';
export const CYCLE_COUNT_MANAGER_ROUTE_PREFIX = '/cycle-count-manager';
export const CYCLE_COUNT_GROUP_ROUTE_PREFIX = '/cycle-count-group';
export const CYCLE_ACTIVE_ROUTE = `${CYCLE_COUNT_ROUTE_PREFIX}/active-cycles`;
export const CYCLE_HISTORICAL_ROUTE = `${CYCLE_COUNT_ROUTE_PREFIX}/historical-cycles`;
export const CYCLE_DETAILS_ROUTE = `${CYCLE_COUNT_ROUTE_PREFIX}/active-cycles/:id`;
export const CYCLE_WAVE_DETAILS_ROUTE = `${CYCLE_COUNT_ROUTE_PREFIX}/active-cycles/wave-details/:id`;
export const CYCLE_MANAGER_ROUTE = `${CYCLE_COUNT_MANAGER_ROUTE_PREFIX}/manager-review`;
export const CYCLE_GROUP_ASSIGNMENT_ROUTE = `${CYCLE_COUNT_GROUP_ROUTE_PREFIX}/group-assignments`;
export const CYCLE_GROUP_MISSING_ASSIGNMENT_ROUTE = `${CYCLE_COUNT_GROUP_ROUTE_PREFIX}/group-missing-assignments`;
