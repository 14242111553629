import React from 'react';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import app from '../../config';

const localizejsEnabled = get(app.general.getItem('localizejs'), 'enabled', false);
const localizejsAppKey = get(app.general.getItem('localizejs'), 'appKey');

export default function () {
    if (localizejsEnabled && localizejsAppKey) {
        return (
            <Helmet
                script={[
                    {
                        type: 'text/javascript',
                        src: 'https://global.localizecdn.com/localize.js',
                    },
                    {
                        type: 'text/javascript',
                        innerHTML: `!function(a){if(!a.Localize){a.Localize={};for(var e=["translate","untranslate","phrase","initialize","translatePage","setLanguage","getLanguage","getSourceLanguage","detectLanguage","getAvailableLanguages","untranslatePage","bootstrap","prefetch","on","off","hideWidget","showWidget"],t=0;t<e.length;t++)a.Localize[e[t]]=function(){}}}(window);`,
                    },
                ]}
            />
        );
    }

    return null;
}
