export const addNotesToAttributesListInDetails = ({ attributes = [], data = {} }) => {
    return {
        attributes: [
            ...attributes,
            {
                label: 'Special Instructions',
                key: 'relatedOrder.customValues.special_instructions',
                render: (notes) => notes || '-',
            },
        ],
        data,
    };
};
