import createConfigurableObject from '../../../utils/configurationObject';
import routes from './routes';
import tabs from './tabs';
import sideMenuItem from './sideMenu';

export default (app) => {
    app.productDetailsTabs = app.productDetailsTabs || createConfigurableObject();
    app.productDetailsTabs.addMany(tabs(app));
    app.routes.addMany(routes(app));
    app.sideMenuItems.addItem('products', sideMenuItem(app));
};
