import { WAVES_SETTINGS_ROUTE } from './consts';

export default () => ({
    waves: {
        id: 'Waves',
        label: 'Waves',
        link: WAVES_SETTINGS_ROUTE,
        permission: 'fulfillments - waves.index',
    },
});
