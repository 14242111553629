import {
    TITLE_PREFIX,
    PRODUCTS_DETAILS_PATH,
    PRODUCTS_STOCK_PATH,
    PRODUCTS_ALLOCATIONS_PATH,
    PRODUCTS_ADJUSTMENTS_PATH,
    PRODUCTS_BARCODES_PATH,
    PRODUCTS_RESERVATONS_PATH,
    PRODUCTS_KITTING_PATH,
    PRODUCTS_MEDIA_PATH,
    PRODUCTS_CYCLE_COUNT_PATH,
} from './consts';

export default () => ({
    overview: {
        id: 'Overview',
        label: 'Overview',
        title: `${TITLE_PREFIX} Overview`,
        link: PRODUCTS_DETAILS_PATH,
        permission: 'products.show',
        order: 10,
    },
    stock: {
        id: 'Stock',
        label: 'Stock',
        title: `${TITLE_PREFIX} Stock`,
        link: PRODUCTS_STOCK_PATH,
        order: 20,
    },
    allocations: {
        id: 'Allocations',
        label: 'Allocations',
        title: `${TITLE_PREFIX} Allocations`,
        link: PRODUCTS_ALLOCATIONS_PATH,
        permission: 'inventory - allocations.index',
        order: 30,
    },
    adjustments: {
        id: 'Adjustments',
        label: 'Adjustments',
        title: `${TITLE_PREFIX} Adjustments`,
        link: PRODUCTS_ADJUSTMENTS_PATH,
        permission: 'inventory - adjustments.index',
        order: 40,
    },
    barcodes: {
        id: 'Barcodes',
        label: 'Barcodes',
        title: `${TITLE_PREFIX} Barcodes`,
        link: PRODUCTS_BARCODES_PATH,
        permission: 'products - barcodes.index',
        order: 50,
    },
    reservations: {
        id: 'Reservations',
        label: 'Reservations',
        title: `${TITLE_PREFIX} Reservations`,
        link: PRODUCTS_RESERVATONS_PATH,
        permission: 'inventory - reservations.index',
        order: 60,
    },
    kitting: {
        id: 'Kitting',
        label: 'Kitting',
        title: `${TITLE_PREFIX} Kitting`,
        link: PRODUCTS_KITTING_PATH,
        permission: 'products - kits.index',
        order: 70,
    },
    media: {
        id: 'Media',
        label: 'Media',
        title: `${TITLE_PREFIX} Media`,
        link: PRODUCTS_MEDIA_PATH,
        order: 80,
    },
    cycleCount: {
        id: 'Cycle Count',
        label: 'Cycle Count',
        title: `${TITLE_PREFIX} Cycle Count`,
        link: PRODUCTS_CYCLE_COUNT_PATH,
        permission: 'cycle-counts.count group list',
        order: 80,
    },
});
