// PAGE TITLE
export const TITLE_PREFIX = 'Users';
export const PRODUCTIVITY_REPORT_TITLE_PREFIX = 'Productivity Report';
export const PROFILE_TITLE_PREFIX = 'My Profile';

// ROUTES
export const USERS_ROUTE_PREFIX = 'settings/users';
export const USERS_INDEX_ROUTE = `/${USERS_ROUTE_PREFIX}`;
export const USERS_DETAILS_PATH = `/${USERS_ROUTE_PREFIX}/:id`;
export const USERS_DETAILS_ROUTE = `/${USERS_ROUTE_PREFIX}`;
export const USERS_CHANGE_PASSWORD_FORM_PATH = `/${USERS_ROUTE_PREFIX}/change-password/:id`;
export const USERS_CHANGE_PASSWORD_FORM_ROUTE = `/${USERS_ROUTE_PREFIX}/change-password`;
export const USERS_FORM_PATH = `/${USERS_ROUTE_PREFIX}/:action(add|edit)/:id?`;
export const USERS_CREATE_FORM_ROUTE = `/${USERS_ROUTE_PREFIX}/add`;
export const USERS_EDIT_FORM_ROUTE = `/${USERS_ROUTE_PREFIX}/edit`;

export const USERS_PRODUCTIVITY_REPORT_ROUTE = '/users-productivity-report';
export const USERS_PROFILE_ROUTE = '/profile';
